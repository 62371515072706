import { useState, useContext, useRef } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  createStore,
  getStoreCategoryList,
  updateStoreDetails,
} from "../../../services/inventory";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  cleanObject,
  getRandomIntInclusive,
  getUrlQuerysection,
} from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { pathConstants } from "../../../routes/pathContants";
import { useGetAccountingBanksList } from "../../../hooks/api/queries";
import countriesList from "../../../localdata/worldCountries.json";

const useStoreForm = ({
  storeInfo,
  handleFetchStores,
  isEditMode,
  closeWidget,
  countries,
  states,
}) => {
  const { business_phone_code, currency_code, currency_symbol } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const currencyList = countriesList.filter(
    (obj, index, self) =>
      index === self.findIndex((t) => t.currencyName === obj.currencyName),
  );
  const [storeDetails, setStoreDetails] = useState({
    name: storeInfo?.name || "",
    address: storeInfo?.address || "",
    subaccount_label: storeInfo?.subaccount || "",
    store_category: storeInfo?.store_category?.id || "",
    store_category_label: storeInfo?.store_category?.name || "",
    is_warehouse: storeInfo?.is_warehouse || false,
    vat: storeInfo?.vat,
    allow_generate_bill: storeInfo?.allow_generate_bill || false,
    allow_table: storeInfo?.allow_table || false,
    allow_waybill: storeInfo?.allow_waybill || false,
    allow_prescription: storeInfo?.allow_prescription || false,
    allow_custom_price: storeInfo?.allow_custom_price || false,
    allow_section: storeInfo?.allow_section || false,
    phone_number: storeInfo?.phone_number?.substring(3) || "",
    phone_code: business_phone_code,
    credit_limit: storeInfo?.credit_limit || "",
    default_bank_account: storeInfo?.default_bank_account?.id || "",
    source: "user_entry",
    currency_code: storeInfo?.currency_code || currency_code || "",
    currency_symbol: storeInfo?.currency_symbol || currency_symbol || "",
    country_ids: storeInfo?.countries?.map((country) => country?.id) || [],
    state_ids: storeInfo?.states?.map((state) => state?.id) || [],
    city_ids: (() => {
      const cities = storeInfo?.cities?.map((city) => city?.id);
      const newCities = {};
      storeInfo?.states?.forEach((saved_state) => {
        newCities[saved_state?.id] = states
          ?.find((state) => `${state?.id}` === `${saved_state?.id}`)
          ?.cities?.filter((city) => cities?.includes(city?.id))
          ?.map((city) => city?.id);
      });
      return newCities;
    })(),
  });

  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queries = getUrlQuerysection();
  const stateInputRef = useRef();

  const { data: category_list } = useQuery(["storeCategoryList"], () =>
    getStoreCategoryList().then((res) => {
      return res?.data?.data;
    }),
  );

  // create a new store
  const { mutate: addStore, isLoading: isAddingStore } = useMutation((data) =>
    createStore(data),
  );
  const handleAddStore = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const newCityIds = Object.values({ ...storeDetails?.city_ids }).flat();
    const data = cleanObject(
      {
        ...storeDetails,
        phone_number:
          storeDetails?.phone_number !== ""
            ? `${storeDetails.phone_code}${storeDetails.phone_number}`
            : null,
        city_ids: newCityIds,
      },
      ["store_category_label", "subaccount_label"],
    );

    addStore(data, {
      onSuccess: () => {
        handleFetchStores();
        triggerToast("Store creation successful", "success");
        closeWidget();
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  // Edit store properties
  const { mutate: updateStore, isLoading: isEditingStore } = useMutation(
    (data) => updateStoreDetails(data),
  );
  const handleEditStore = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const newCityIds = Object.values({ ...storeDetails?.city_ids }).flat();
    const data = cleanObject(
      {
        ...storeDetails,
        phone_number:
          storeDetails?.phone_number !== ""
            ? `${storeDetails.phone_code}${storeDetails.phone_number}`
            : null,
        city_ids: newCityIds,
      },
      ["store_category_label", "subaccount_label"],
    );
    updateStore(
      { data, id: storeInfo?.id },
      {
        onSuccess: () => {
          handleFetchStores();
          queryClient.invalidateQueries(["getStoreDetails", storeInfo?.id]);
          navigate(pathConstants.INVENTORY_STORES);
          triggerToast("Store update successful", "success");
          closeWidget();
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const submitStoreInfo = () => {
    if (isEditMode) handleEditStore();
    else handleAddStore();
  };

  // list of account

  const { isLoading: isLoadingBankAccountsList, data: bankAccount } =
    useGetAccountingBanksList({ queries });

  return {
    isAddingStore,
    isEditingStore,
    storeDetails,
    category_list: category_list || [],
    currencyList,
    // isLoadingCountries,
    // isLoadingStates,
    states: states?.states,
    countries,
    stateInputRef,
    setStoreDetails,
    submitStoreInfo,
    bankAccount,
    isLoadingBankAccountsList,
  };
};

export default useStoreForm;
