import { useTable } from "react-table";
import { useContext, useMemo } from "react";
import {
  activatePaymentGateway,
  getPaymentGateways,
} from "../../../../../../../services/inventory";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ToastContext } from "../../../../../../../hooks/context";
import { truncateString } from "../../../../../../../helpers";

const usePaymentMethod = () => {
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();

  const { isLoading: isLoadingPaymentMethods, data: dataPaymentMethods } =
    useQuery(
      ["payment-gateways"],
      () => getPaymentGateways().then((res) => res.data?.data),
      { staleTime: Infinity },
    );

  const { mutate: mutatePaymentGateway } = useMutation({
    mutationFn: (id) => activatePaymentGateway(id),
    onSuccess: (data) => {
      queryClient.refetchQueries(["payment-gateways"]);
      triggerToast(data.data.detail, "success");
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Payment Method",
        accessor: "type",
      },
      {
        Header: "Secret Key",
        accessor: "secret_key",
        Cell: ({ cell: { value } }) => truncateString(value, 30),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: dataPaymentMethods || [] });

  return {
    instance,
    isLoadingPaymentMethods,
    dataPaymentMethods,
    mutatePaymentGateway,
  };
};

export default usePaymentMethod;
