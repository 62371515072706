import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getStoreList } from "../../../../services/inventory";
import { setStoreList } from "../../../../store/modules/profile";
import {
  useGetCountries,
  useGetCountryDetails,
} from "../../../../hooks/api/queries/useBusiness";

const useStores = () => {
  const [isCreateStoreModal, setIsCreateStoreModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const dispatch = useDispatch();

  const {
    isLoading: isFetchingStores,
    data: storeList,
    refetch: handleFetchStores,
  } = useQuery(
    ["storeList"],
    () => getStoreList().then((res) => res?.data?.data),
    {
      onSuccess: (data) => {
        dispatch(setStoreList(data));
      },
    },
  );

  const { data: countries, isLoading: isLoadingCountries } = useGetCountries();
  const { data: states, isLoading: isLoadingStates } =
    useGetCountryDetails(selectedCountry);

  return {
    isCreateStoreModal,
    storeList: storeList || [],
    isFetchingStores: isLoadingCountries || isFetchingStores,
    isLoadingStates,
    states: states?.states,
    countries,
    setSelectedCountry,
    handleFetchStores,
    setIsCreateStoreModal,
  };
};

export default useStores;
