import styles from "./MenuPageLayout.module.scss";
import { Outlet } from "react-router-dom";
import Logo from "../../assets/logo";
import primaryComponents from "../../components/primaryComponents";
import secondaryComponents from "../../components/secondaryComponents";
import { useMenuPageLayout } from "./useMenuPageLayout";
import { CartIcon, Location } from "../../assets/svgIcons";

const MenuPageLayout = () => {
  const {
    isLoadingMenuObject,
    menuCartItems,
    menuSearchText,
    showLocationChange,
    tempMenuId,
    showCartItems,
    menuObject,
    setMenuSearchText,
    setShowCartItems,
    setShowLocationChange,
  } = useMenuPageLayout();

  return (
    <>
      <secondaryComponents.LoaderHelper
        isLoading={isLoadingMenuObject || !menuObject}
        classNames={"mt-5"}
      >
        <div className={styles.menuLayout}>
          <div
            style={{
              background: menuObject?.primary_colour ? "white" : "#7647ee",
            }}
            className={styles.navbar}
          >
            {menuObject?.logo_url && (
              <img
                src={menuObject?.logo_url}
                width={"80px"}
                height={"60px"}
                alt="logo"
                className={styles.logo}
              />
            )}

            <primaryComponents.SearchField
              value={""}
              placeholder={"Search for a storefront"}
              triggerOnInput={true}
              showsearchBtn={true}
              trigger={(value) => setMenuSearchText(value)}
              classNames={`${styles.search}`}
              borderColor={
                menuObject?.primary_colour
                  ? menuObject?.primary_colour
                  : "#7647ee"
              }
            />

            <div className={styles.navbar__left}>
              {menuObject?.logo_url && (
                <img
                  src={menuObject?.logo_url}
                  width={"80px"}
                  height={"60px"}
                  alt="logo"
                  className={styles.logo2}
                />
              )}
              <primaryComponents.Button
                classNames={"btn"}
                style={{
                  borderColor: menuObject?.primary_colour,
                  background: "#fff",
                  color: menuObject?.primary_colour,
                }}
                onClick={() => setShowLocationChange(true)}
              >
                {" "}
                <Location color={menuObject?.primary_colour} />
                <span className="dark--text ml-2 mr-3">
                  {menuObject?.store?.country}
                </span>
                <span
                  style={{
                    color: menuObject?.primary_colour
                      ? menuObject?.primary_colour
                      : "#7647ee",
                    textDecoration: "underline",
                  }}
                >
                  Change
                </span>
              </primaryComponents.Button>

              {menuObject?.allow_payment &&
              (menuObject?.allow_in_store_checkout ||
                menuObject?.allow_delivery) ? (
                <primaryComponents.Button
                  classNames={`btn ${styles.cartBtn}`}
                  style={{
                    borderColor: menuObject?.primary_colour,
                    background: "#fff",
                    color: menuObject?.primary_colour,
                    padding: "0 15px",
                    overflow: "visible",
                  }}
                  onClick={() => setShowCartItems(true)}
                >
                  <secondaryComponents.ToolTipV2 tipText="Cart" position="left">
                    <CartIcon
                      color={
                        menuObject?.primary_colour
                          ? menuObject?.primary_colour
                          : "#7647ee"
                      }
                    />
                    <span
                      className={`${styles.cart} small-text-size`}
                      style={{
                        background: menuObject?.secondary_colour,
                      }}
                    >
                      {menuCartItems?.length}
                    </span>
                  </secondaryComponents.ToolTipV2>
                </primaryComponents.Button>
              ) : null}
            </div>
          </div>

          <div className={styles.menuOutlet}>
            <Outlet
              context={{
                menuSearchText,
                showCartItems,
                menuObject,
                setShowCartItems,
              }}
            />
            <div className={styles.lumiPoweredBy}>
              <img src={Logo.powered_logo} alt="logo" />
              <div>
                <p className={"small-text-size dark--text"}>Powered by</p>
                <h4 className={"purple--text"}>Lumi Business</h4>
              </div>
            </div>
          </div>
        </div>
      </secondaryComponents.LoaderHelper>
      <secondaryComponents.Modal
        isActive={showLocationChange}
        closeModal={() => setShowLocationChange(false)}
        width={1000}
        closeViaOverlay={tempMenuId ? true : false}
      >
        <secondaryComponents.StoreFrontLocationChangeModal
          primarColor={menuObject?.primary_colour}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default MenuPageLayout;
