import useCreateExpense from "./useCreateExpense.js";
import styles from "./CreateExpense.module.scss";
import primaryComponents from "../../primaryComponents/index.js";
import Validation from "./Validation.js";
import secondaryComponents from "../index.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

const CreateExpense = ({ closeWidget, handleFetchExpenses }) => {
  const [isNewExpenseCategory, setIsNewExpenseCategory] = useState(false);

  const {
    isCreatingExpense,
    isLoading,
    bankList,
    storeList,
    expenseCategories,
    expenseDetails,
    isDisbursmentForm,
    setIsDisbursmentForm,
    setExpenseDetails,
    submitExpense,
    handleProofUpload,
    handleResolveAccountNumber,
    handleFetchCategories,
  } = useCreateExpense({ closeWidget, handleFetchExpenses });
  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    register,
    setValue,
  } = Validation({ expenseDetails, isDisbursmentForm });

  useEffect(() => {
    window.setExpenseFormValue = setValue;
  }, []);

  return (
    <div className={styles.createExpense}>
      <div className="mb-3">
        <FontAwesomeIcon
          onClick={() => {
            if (isDisbursmentForm) {
              setIsDisbursmentForm(false);
              window.setExpenseFormValue("isDisbursmentForm", false);
            } else closeWidget();
          }}
          icon="angle-left"
          style={{ fontSize: "25px", cursor: "pointer" }}
        />
      </div>

      {!isDisbursmentForm ? (
        <>
          <h4 className="mb-1">Create Expense</h4>
          <p>Record and initiate disbursements.</p>
        </>
      ) : (
        <>
          <h4 className="mb-1">Disbursement Account</h4>
          <p>Provide the following details for disbursement</p>
        </>
      )}

      <secondaryComponents.LoaderHelper isLoading={isLoading} classNames="mt-5">
        <form
          className={styles.createExpense__form}
          onSubmit={handleSubmit(submitExpense)}
        >
          {!isDisbursmentForm ? (
            <>
              <primaryComponents.SelectFieldV2
                control={control}
                name="store"
                label="Store"
                isRequired
                options={storeList}
                idKey="id"
                nameKey="name"
                onChange={(value, label) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    store: value,
                    store_label: label,
                  }));
                }}
                value={
                  expenseDetails?.store
                    ? expenseDetails?.store_label
                    : "Select stores"
                }
                classNames="store-select"
                isSearchable={false}
                errorMessage={errors.store}
              />

              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                label="Expense Name"
                placeholder="Enter expense name"
                name="expenseName"
                value={expenseDetails?.name}
                isRequired
                register={register}
                errorMessage={errors.expenseName}
                onKeyUp={() =>
                  errors.expenseName !== undefined && trigger("expenseName")
                }
                onChange={({ target: { value } }) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    name: value,
                  }));
                }}
              />

              <primaryComponents.AmountFieldV2
                control={control}
                classNames="white"
                label="Amount"
                placeholder="Enter amount"
                value={expenseDetails?.amount}
                name="amount"
                currency_code={
                  storeList?.find(
                    (storeItem) =>
                      `${storeItem?.id}` === `${expenseDetails.store}`,
                  )?.currency_code
                }
                isRequired
                register={register}
                errorMessage={errors.amount}
                onKeyUp={() => errors.amount !== undefined && trigger("amount")}
                onChange={({ target: { value } }) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    amount: value,
                  }));
                }}
              />

              <div>
                <label>
                  <span className="text-danger">*</span>
                  <span>
                    Category (
                    <span onClick={() => setIsNewExpenseCategory(true)}>
                      Add new category
                    </span>
                    )
                  </span>
                </label>
                <primaryComponents.SelectFieldV2
                  label={null}
                  isRequired
                  options={expenseCategories}
                  nameKey={"name"}
                  idKey={"id"}
                  value={
                    expenseDetails?.category
                      ? expenseDetails.category_label
                      : "Choose Category"
                  }
                  name="category"
                  control={control}
                  errorMessage={errors.category}
                  onChange={(value, label) => {
                    setExpenseDetails((prevState) => ({
                      ...prevState,
                      category: value,
                      category_label: label,
                    }));
                  }}
                />
              </div>

              <primaryComponents.SelectFieldV2
                label="Payment Method"
                isRequired
                options={[
                  { name: "Transfer", id: "transfer" },
                  { name: "Card", id: "card" },
                  { name: "Cash", id: "cash" },
                ]}
                nameKey={"name"}
                idKey={"id"}
                name="paymentMethod"
                control={control}
                value={
                  expenseDetails?.payment_method
                    ? expenseDetails?.payment_method_label
                    : "Select payment method for this expense"
                }
                errorMessage={errors.paymentMethod}
                onChange={(value, label) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    payment_method: value,
                    payment_method_label: label,
                  }));
                }}
              />

              <primaryComponents.DateInputV2
                classNames="white"
                label="Expense Date"
                control={control}
                name="expenseDate"
                isRequired
                register={register}
                errorMessage={errors.expenseDate}
                value={expenseDetails?.expense_date}
                onKeyUp={() =>
                  errors.expenseDate !== undefined && trigger("expenseDate")
                }
                onChange={({ target: { value } }) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    expense_date: value,
                  }));
                }}
              />

              <primaryComponents.TextAreaV2
                classNames="white"
                label="Description"
                placeholder="Describe this expense "
                control={control}
                name="description"
                value={expenseDetails?.description}
                register={register}
                errorMessage={errors.description}
                onKeyUp={() =>
                  errors.description !== undefined && trigger("description")
                }
                onChange={({ target: { value } }) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    description: value,
                  }));
                }}
              />

              <div>
                <label>
                  {/* <span className="text-danger">*</span> */}
                  <span>Expense Proof</span>
                </label>
                <primaryComponents.FileInput
                  name="Drag and drop document here or Browse"
                  onChange={(document) => {
                    if (document) handleProofUpload(document);
                  }}
                />
              </div>

              <secondaryComponents.CountryItemPermission>
                <div className="mb-3">
                  <label>
                    <span className="text-danger">*</span>
                    <span>Re-imburse or Disburse Funds For This Expense?</span>
                  </label>
                  <div className="d-flex" style={{ gap: "10px" }}>
                    <primaryComponents.BoxedRadioInput
                      name="reImburse"
                      value={true}
                      id="YesReImburse"
                      isChecked={expenseDetails.make_refund}
                      classNames="whites"
                      setRadioValue={() => {
                        setExpenseDetails((prevState) => ({
                          ...prevState,
                          make_refund: true,
                        }));
                      }}
                    >
                      Yes
                    </primaryComponents.BoxedRadioInput>
                    <primaryComponents.BoxedRadioInput
                      name="reImburse"
                      value={false}
                      id="NoReImburse"
                      isChecked={!expenseDetails.make_refund}
                      classNames="whites"
                      setRadioValue={() => {
                        setExpenseDetails((prevState) => ({
                          ...prevState,
                          make_refund: false,
                        }));
                      }}
                    >
                      No
                    </primaryComponents.BoxedRadioInput>
                  </div>
                </div>
              </secondaryComponents.CountryItemPermission>
            </>
          ) : (
            <>
              <>
                <primaryComponents.SelectFieldV2
                  control={control}
                  name="bankCode"
                  label="Bank"
                  isRequired
                  value={
                    expenseDetails?.bank_code
                      ? expenseDetails?.bank_code_label
                      : "Select Bank"
                  }
                  options={bankList}
                  nameKey="bank_name"
                  idKey="bank_code"
                  onChange={(value, label) => {
                    setExpenseDetails((prevState) => ({
                      ...prevState,
                      bank_code: value,
                      bank_code_label: label,
                    }));
                    if (expenseDetails?.account_number.length === 10)
                      handleResolveAccountNumber(
                        value,
                        expenseDetails?.account_number,
                      );
                  }}
                  errorMessage={errors.bankCode}
                />
              </>
              <div>
                <primaryComponents.InputFieldV2
                  control={control}
                  label="Account Number"
                  isRequired
                  placeholder="Enter 10-digit account number"
                  name="accountNumber"
                  classNames="white"
                  floatError={false}
                  value={expenseDetails?.account_number}
                  onChange={({ target: { value } }) => {
                    setExpenseDetails((prevState) => ({
                      ...prevState,
                      account_number: value,
                    }));
                    if (value.length === 10)
                      handleResolveAccountNumber(
                        expenseDetails?.bank_code,
                        value,
                      );
                  }}
                  onKeyUp={() =>
                    errors.accountNumber !== undefined &&
                    trigger("accountNumber")
                  }
                  register={register}
                  errorMessage={errors.accountNumber}
                />
                {expenseDetails?.account_name && (
                  <p className="small-text-size purple--text">
                    {expenseDetails?.account_name}
                  </p>
                )}
              </div>
            </>
          )}

          <primaryComponents.Button
            classNames="btn btn--primary cover mt-auto"
            type="submit"
            isLoading={isCreatingExpense}
            loadingText="Submitting..."
            isDisabled={isDisbursmentForm && !expenseDetails?.account_name}
          >
            {isDisbursmentForm || !expenseDetails.make_refund
              ? "Submit"
              : "Next"}
          </primaryComponents.Button>
        </form>
      </secondaryComponents.LoaderHelper>
      <secondaryComponents.Modal
        isActive={isNewExpenseCategory}
        width="500px"
        closeModal={() => setIsNewExpenseCategory(false)}
      >
        <secondaryComponents.CreateExpenseCategory
          closeWidget={() => setIsNewExpenseCategory(false)}
          handleFetchCategories={handleFetchCategories}
        />
      </secondaryComponents.Modal>
    </div>
  );
};
export default CreateExpense;
