import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  accountName: yup.string().required("Account name is required"),
  bankName: yup.string().required("Bank name is required"),
  accountNumber: yup.string().required("Account Number is required"),
  storeIds: yup.string().required("store is required"),
});

const Validation = ({ bankDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      bankName: bankDetails?.bank_name,
      accountName: bankDetails?.account_name,
      accountNumber: bankDetails?.account_number,
      storeIds: bankDetails?.store_id,
    },
  });
};

export default Validation;
