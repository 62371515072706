import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetStorefrontMenus } from "../../hooks/api/queries";
import { useDispatch } from "react-redux";
import {
  resetMenuCartItems,
  setBusinessId,
  setMenuId,
} from "../../store/modules/menuCart";

export const useMenuPageLayout = () => {
  const { id } = useParams();
  const [search] = useSearchParams();
  const businessIds = id?.split("-");
  const [tempMenuId, setTempMenuId] = useState(
    search.get("menu_id")
      ? search.get("menu_id")
      : businessIds?.length > 1
        ? id
        : null,
  );
  const { menuCartItems, menuId } = useSelector(
    (state) => state.menuCartReducer,
  );
  const [menuSearchText, setMenuSearchText] = useState("");
  const [showCartItems, setShowCartItems] = useState(false);
  const [showLocationChange, setShowLocationChange] = useState(
    tempMenuId ? false : true,
  );
  const { isLoading: isLoadingMenuObject, data: menuObject } =
    useGetStorefrontMenus(tempMenuId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (menuObject) {
      dispatch(setBusinessId(businessIds?.[0]));
      dispatch(setMenuId(menuObject?.id));
      const storedCartItems = localStorage.getItem(
        `menuCartItems${menuObject?.id}`,
      )
        ? JSON.parse(localStorage.getItem(`menuCartItems${menuObject?.id}`))
        : [];

      dispatch(
        resetMenuCartItems(storedCartItems?.length > 0 ? storedCartItems : []),
      );
    }
  }, [menuObject]);

  useEffect(() => {
    if (!tempMenuId) setTempMenuId(menuId);
  }, [menuId]);

  return {
    isLoadingMenuObject,
    menuCartItems,
    menuSearchText,
    showLocationChange,
    showCartItems,
    menuObject,
    tempMenuId,
    setShowCartItems,
    setMenuSearchText,
    setShowLocationChange,
  };
};
