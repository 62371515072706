import Validation from "./Validation";
import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./AddBankAccount.module.scss";
import useBankAccountLogic from "./useBankAccountLogic";

const AddBankAccount = ({
  bankInfo,
  isEditMode = false,
  handleFetchBanks = () => {},
  closeWidget = () => {},
}) => {
  const {
    bankDetails,
    setBankDetails,
    storeList,
    handleAddBank,
    handleEditBank,
    editBankMutation,
    createBankMutation,
  } = useBankAccountLogic({
    closeWidget,
    bankInfo,
    handleFetchBanks,
  });
  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation({ bankDetails });
  return (
    <div className={styles.allAccounting}>
      <FontAwesomeIcon
        onClick={() => closeWidget(false)}
        className="d-block ml-auto"
        icon="times"
        style={{ fontSize: "30px" }}
      />

      <div className="mt-4">
        <h5>{isEditMode ? "Update Bank Details" : "Add Bank Details"}</h5>
        <p className="small-text-size">
          {isEditMode
            ? "Update and use bank account for specific expenses"
            : "Add and use bank account for specific expenses."}
        </p>

        <div className={styles.formSection}>
          <div className="w-100 cover">
            <primaryComponents.InputFieldV2
              control={control}
              classNames="white"
              name="accountName"
              label="Account Name"
              placeholder="Enter account name"
              value={bankDetails?.account_name}
              onChange={({ target: { value } }) => {
                setBankDetails((prev) => ({
                  ...prev,
                  account_name: value,
                }));
              }}
              errorMessage={errors.accountName}
              onKeyUp={() =>
                errors.accountName !== undefined && trigger("accountName")
              }
              register={register}
            />
          </div>
          <div className="w-100 cover">
            <primaryComponents.InputFieldV2
              control={control}
              name="bankName"
              label={"Bank Name"}
              isRequired={true}
              placeholder="Enter bank name"
              value={bankDetails?.bank_name}
              onChange={({ target: { value } }) => {
                setBankDetails((prev) => ({
                  ...prev,
                  bank_name: value,
                }));
              }}
              classNames="white"
              register={register}
              onKeyUp={() =>
                errors.bankName !== undefined && trigger("bankName")
              }
            />
          </div>
          <div className="w-100 cover">
            <primaryComponents.InputFieldV2
              control={control}
              classNames="white"
              name="accountNumber"
              label="Account Number"
              placeholder="Enter account number"
              value={bankDetails?.account_number}
              onChange={({ target: { value } }) => {
                setBankDetails((prev) => ({
                  ...prev,
                  account_number: value,
                }));
              }}
              errorMessage={errors.accountNumber}
              onKeyUp={() =>
                errors.accountNumber !== undefined && trigger("accountNumber")
              }
              register={register}
            />
          </div>
          <div className="w-100 cover">
            <primaryComponents.InputFieldV2
              control={control}
              classNames="white"
              name="balance"
              label="Balance"
              placeholder="Enter balance"
              defaultValue={bankDetails?.balance}
              value={bankDetails?.balance}
              onChange={({ target: { value } }) => {
                setBankDetails((prev) => ({
                  ...prev,
                  balance: value,
                }));
              }}
              register={register}
            />
          </div>
          <div className="w-100 cover">
            <primaryComponents.SelectFieldV2
              options={storeList}
              control={control}
              name="storeIds"
              label={"Select store(s)"}
              isRequired={true}
              nameKey="name"
              value={
                bankDetails?.store_id ? bankInfo?.store?.name : "Select stores"
              }
              onChange={(value) => {
                setBankDetails((prev) => ({
                  ...prev,
                  store_id: value,
                }));
              }}
              idKey={"id"}
              defaultValue={bankDetails?.store_ids}
              classNames="store-select"
              register={register}
              errorMessage={errors.storeIds}
            />
          </div>
        </div>
      </div>

      {isEditMode ? (
        <primaryComponents.Button
          onClick={handleSubmit(handleEditBank)}
          classNames="btn btn--primary cover mt-auto"
          isDisabled={editBankMutation.isLoading}
          isLoading={editBankMutation.isLoading}
          loadingText={"Updating..."}
        >
          Update
        </primaryComponents.Button>
      ) : (
        <primaryComponents.Button
          onClick={handleSubmit(handleAddBank)}
          classNames="btn btn--primary cover mt-auto"
          isDisabled={createBankMutation.isLoading}
          isLoading={createBankMutation.isLoading}
          loadingText={"Adding..."}
        >
          Add
        </primaryComponents.Button>
      )}
    </div>
  );
};

export default AddBankAccount;
