import { useTable } from "react-table";
import { useMemo, useState } from "react";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { DeleteIcon } from "../../../assets/svgIcons";
import { useGetAccountingBankDetails } from "../../../hooks/api/queries";

const useAccountingsListLogic = ({ banks, refetch }) => {
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedDeleteBank, setSelectedDeleteBank] = useState(null);

  const { isLoading: isFetchingBankInfo, data: bankInfo } =
    useGetAccountingBankDetails(selectedBank || selectedDeleteBank);
  const columns = useMemo(
    () => [
      {
        Header: "Bank",
        accessor: "bank_name",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
      {
        Header: "Account Number",
        accessor: "account_number",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
      {
        Header: "Account Name",
        accessor: "account_name",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ cell: { value } }) =>
          value ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                position: "relative",
              }}
            >
              {value?.name}
            </div>
          ) : (
            "N/A"
          ),
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell: { row } }) => (
          <div className="d-flex" style={{ gap: "5px" }}>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Accounting"}
              permissionChildKey="can_update_ledger_account"
            >
              <primaryComponents.Button
                classNames={"btn btn--primary smallBtn"}
                isDisabled={!row?.original}
                onClick={() => {
                  if (row?.original?.id) {
                    setSelectedBank(row?.original?.id);
                  }
                }}
              >
                Update
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Accounting"}
              permissionChildKey="can_delete_ledger_account"
            >
              <primaryComponents.Button
                classNames={"btn btn--outline-red smallBtn"}
                onClick={() => {
                  if (row?.original?.id) {
                    setSelectedDeleteBank(row?.original?.id);
                  }
                }}
              >
                <DeleteIcon type="menu" />
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>
        ),
      },
    ],
    [selectedBank, refetch],
  );

  const instance = useTable({ columns, data: banks });
  return {
    instance,
    selectedBank,
    banks,
    refetch,
    selectedDeleteBank,
    setSelectedDeleteBank,
    setSelectedBank,
    bankInfo,
    isFetchingBankInfo,
  };
};

export default useAccountingsListLogic;
