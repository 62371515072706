import styles from "./CreditLog.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { useCreditLog } from "./useCreditLog";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import { motion } from "framer-motion";

const CreditLog = () => {
  const {
    isFetchingCredits,
    creditLog,
    metaDetails,
    search,
    filterByStoreId,
    storeList,
    storeHasMultipleCurrency,
    currentStore,
    setCurrentStore,
    handleFetchCredits,
    gotoPage,
    searchCredits,
  } = useCreditLog();
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Credit Log"
      description="View all credits"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${styles.creditLog} ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Credit Log</h4>
            <p>Update your list of debtors and the amounts they owe you here</p>
          </div>
          <div className={styles.creditLog__content}>
            <div className={styles.creditLog__content__header}>
              <primaryComponents.SearchField
                classNames={styles.searchField}
                placeholder="Search for a customer by name"
                trigger={(value) => searchCredits(value)}
                value={search.get("query") !== null ? search.get("query") : ""}
                triggerOnInput={true}
                showsearchBtn={true}
              />
              <div style={{ width: "200px" }}>
                <primaryComponents.SelectField
                  options={
                    storeHasMultipleCurrency
                      ? [...storeList]
                      : [
                          {
                            name: "All stores",
                            id: "ALL_STORES",
                          },
                          ...storeList,
                        ]
                  }
                  idKey="id"
                  nameKey="name"
                  onChange={(value) => {
                    setCurrentStore(value);
                    filterByStoreId(value);
                  }}
                  value={
                    storeList?.find(
                      (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                    )?.name || "All stores"
                  }
                  floatError={false}
                  classNames="store-select"
                />
              </div>
            </div>
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingCredits}
              classNames="mt-5"
            >
              <secondaryComponents.CreditLogTable
                metaDetails={metaDetails}
                creditLog={creditLog}
                handleFetchCredits={handleFetchCredits}
                gotoPage={gotoPage}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default CreditLog;
