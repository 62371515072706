import primaryComponents from "../../primaryComponents";
import Icon from "../../../assets/icons";
import { useTable } from "react-table";
import { useEffect, useMemo, useState } from "react";
import secondaryComponents from "..";
import { formatCurrency } from "../../../helpers";
import { useSelector } from "react-redux";

export const useBulkRestockTable = ({
  restockData,
  setRestockData,
  currentStore,
  control,
  trigger,
  errors,
}) => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [checkError, setCheckError] = useState(false);
  useEffect(() => {
    const newData = [...restockData];
    for (const key in errors?.restockProperties) {
      newData[key].errors = {};
      for (const key2 in errors?.restockProperties[key]) {
        newData[key].errors[key2] =
          errors?.restockProperties?.[key]?.[key2]?.message;
      }
    }
    newData?.forEach((item, index) => {
      if (!errors?.restockProperties?.[index]) item.errors = undefined;
    });
    setRestockData(newData);
  }, [errors?.restockProperties, checkError]);

  const deleteRow = (index) => {
    const newData = [...instance?.data];
    newData.splice(index, 1);
    setRestockData(newData);
  };

  const columns = useMemo(() => {
    const headers = [
      {
        Header: "Product Name",
        accessor: "name",
        Cell: ({ cell: { value }, row }) => (
          <span className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => deleteRow(row?.index)}
              className="mr-2"
              style={{
                border: "0.5px solid #E02020",
                height: "24px",
                width: "28px",
                backgroundColor: "white",
                borderRadius: "3px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                outline: "none",
              }}
            >
              <img src={Icon.deleteIcon} alt="" />
            </button>
            <secondaryComponents.ConcatenateWithTooltip
              value={value}
              noOfCharacters={200}
            />
          </span>
        ),
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        Cell: ({ cell: { value }, row }) => (
          <primaryComponents.InputFieldV2
            name={`restockProperties.${row?.index}.quantity`}
            control={control}
            classNames="white"
            isRequired
            type="number"
            step={0.01}
            min={0.01}
            value={value}
            errorMessage={
              row?.original?.errors?.quantity
                ? {
                    message: row?.original?.errors?.quantity,
                  }
                : undefined
            }
            onKeyUp={() => {
              errors?.restockProperties?.[row?.index]?.quantity &&
                trigger(`restockProperties.${row?.index}.quantity`);
              setCheckError((prev) => !prev);
            }}
            onChange={({ target: { value } }) => {
              const newData = [...instance?.data];
              newData[row?.index].quantity = value;
              setRestockData(newData);
            }}
          />
        ),
      },
      {
        Header: `Cost Price (${
          storeList?.find(
            (storeItem) => `${storeItem?.id}` === `${currentStore}`,
          )?.currency_code
        })`,
        accessor: "cost_price",
        Cell: ({ cell: { value }, row }) => (
          <primaryComponents.InputFieldV2
            control={control}
            name={`restockProperties.${row?.index}.cost_price`}
            value={value}
            classNames="white"
            onChange={({ target: { value } }) => {
              const newData = [...instance?.data];
              newData[row?.index].cost_price = value;
              setRestockData(newData);
            }}
          />
        ),
      },
      {
        Header: `Selling Price (${
          storeList?.find(
            (storeItem) => `${storeItem?.id}` === `${currentStore}`,
          )?.currency_code
        })`,
        accessor: "pricings",
        Cell: ({ cell: { value: pricings }, row }) => (
          <primaryComponents.InputFieldV2
            control={control}
            name={`restockProperties.${row?.index}.selling_price`}
            value={pricings?.[0]?.price || ""}
            classNames="white"
            onChange={({ target: { value } }) => {
              const newData = [...instance?.data];
              newData[row?.index].pricings[0].price = value;
              setRestockData(newData);
            }}
          />
        ),
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        Cell: ({ cell: { value }, row }) => (
          <primaryComponents.DateInputV2
            control={control}
            name={`restockProperties.${row?.index}.expiry_date`}
            value={value || ""}
            onChange={({ target: { value } }) => {
              const newData = [...instance?.data];
              newData[row?.index].expiry_date = value;
              setRestockData(newData);
            }}
          />
        ),
      },
      {
        Header: "Discount Rate",
        accessor: "",
        Cell: ({ row }) => (
          <primaryComponents.PercentFieldV2
            control={control}
            name={`restockProperties.${row?.index}.discount_rate`}
            value={row?.original?.pricings?.[0]?.discount_rate || ""}
            classNames="white"
            onChange={({ percentValue }) => {
              const newData = [...instance?.data];
              newData[row?.index].pricings[0].discount_rate = percentValue;
              setRestockData(newData);
            }}
            dividedValue={
              row?.original?.pricings?.[0]?.price === ""
                ? 0
                : row?.original?.pricings?.[0]?.price
            }
          />
        ),
      },
      {
        Header: "Profit Per Unit",
        accessor: "profit",
        Cell: ({ row }) => (
          <primaryComponents.InputField
            classNames="white"
            isDisabled={true}
            value={formatCurrency({
              value:
                (
                  row?.original?.pricings?.[0]?.price -
                  (row?.original?.pricings?.[0]?.price *
                    row?.original?.pricings?.[0]?.discount_rate) /
                    100
                ).toFixed(2) - row?.original?.cost_price,
              currencyCode: storeList?.find(
                (storeItem) => `${storeItem?.id}` === `${currentStore}`,
              )?.currency_code,
            })}
          />
        ),
      },
      {
        Header: "Barcode",
        accessor: "barcode",
        Cell: ({ cell: { value }, row }) => (
          <primaryComponents.InputFieldV2
            control={control}
            name={`restockProperties.${row?.index}.barcode`}
            inputIcon={Icon.barCodeIcon}
            classNames="white"
            value={value}
            onChange={({ target: { value } }) => {
              const newData = [...instance?.data];
              newData[row?.index].barcode = value;
              setRestockData(newData);
            }}
          />
        ),
      },
      // {
      //   Header: "Store",
      //   accessor: "store_name",
      //   Cell: ({ cell: { value } }) => (
      //     <primaryComponents.InputField
      //       value={value}
      //       isDisabled={true}
      //       classNames="white"
      //     />
      //   ),
      // },
      {
        Header: "Pricing Type",
        accessor: "",
        Cell: ({ row }) => (
          <primaryComponents.InputField
            value={row?.original?.pricings?.[0]?.pricing_type?.label}
            isDisabled={true}
            classNames="white"
          />
        ),
      },
      {
        Header: "Discounted Selling Price",
        accessor: "",
        Cell: ({ row }) => (
          <primaryComponents.InputField
            isDisabled={true}
            value={(() => {
              let sellingPrice =
                row?.original?.pricings?.[0]?.price === ""
                  ? 0
                  : row?.original?.pricings?.[0]?.price;
              let discountPercent =
                row?.original?.pricings?.[0]?.discount_rate === ""
                  ? 0
                  : row?.original?.pricings?.[0]?.discount_rate;
              let discount_rate = (sellingPrice * discountPercent) / 100;

              let discountedSellingPrice = sellingPrice - discount_rate;

              return formatCurrency({
                value: discountedSellingPrice,
                currencyCode: storeList?.find(
                  (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                )?.currency_code,
              });
            })()}
            classNames="white"
          />
        ),
      },
    ];

    return headers;
  }, [currentStore]);

  const instance = useTable({
    columns,
    data: restockData,
    autoResetPage: false,
  });

  return { instance };
};
