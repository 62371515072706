import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Controller } from "react-hook-form";

const EstimatedInput = ({
  placeholder = "",
  name = "",
  isRequired,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  onChange = () => {},
  value = "",
  register = () => {},
  onKeyUp = () => {},
  domainOptions = [],
  selectedDomain = "",
  control,
  onDomainChange = () => {},
  ...props
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    if (!isDisabled) {
      setShowDropdown(!showDropdown);
    }
  };

  const handleDomainSelect = (domain) => {
    onDomainChange(domain);
    setShowDropdown(false);
  };

  return (
    <div className="estimated-input-field">
      {label !== null && (
        <label htmlFor={name} data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <div
        className={`slug-input-field__input-section ${
          errorMessage !== undefined && "error-border"
        } ${isDisabled && "disabled"}`}
      >
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange: onControllerChange, value } }) => (
            <input
              name={name}
              value={value}
              onChange={(e) => {
                onControllerChange(e?.target?.value);
                onChange(e);
              }}
              placeholder={placeholder}
              disabled={isDisabled}
              className="slug-input-field__domain-select"
              style={{ height: "100%" }}
              {...props}
            />
          )}
        />
        <p
          onClick={toggleDropdown}
          className="slug-input-field__domain-select small-text-size"
        >
          {selectedDomain.label}
          <FontAwesomeIcon
            icon="angle-down"
            style={{ fontSize: "15px", marginTop: "4px" }}
          />
        </p>
        {showDropdown && (
          <ul className="dropdownMenu">
            {domainOptions.map((option) => (
              <li
                key={option.value}
                onClick={() => handleDomainSelect(option)}
                className="dropdownItem small-text-size"
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>

      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
      >
        {errorMessage !== undefined && <span>{errorMessage.message}</span>}
      </p>
    </div>
  );
};

export default EstimatedInput;
