import "./UpdateCreditWidget.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdateCreditWidgetLogic from "./UpdateCreditWidgetLogic";
import { formatCurrency } from "../../../helpers";
// import { Controller } from "react-hook-form";
import primaryComponents from "../../primaryComponents";
import { useState } from "react";

const UpdateCreditWidget = ({ closeWidget, customer, onSuccess }) => {
  const {
    store,
    setStore,
    storeList,
    amount,
    setAmount,
    isSubmitting,
    paymentMethod,
    setPaymentMethod,
    makeRepayment,
  } = UpdateCreditWidgetLogic({ closeWidget, customer, onSuccess });
  const [paymentType] = useState("partial");

  return (
    <div className="update-credit">
      <FontAwesomeIcon
        onClick={closeWidget}
        className="d-block ml-auto"
        icon="times"
        style={{ fontSize: "25px" }}
      />
      <div className="update-credit__title">Update Credit</div>
      <div className="update-credit__subtitle">
        Record payments made by your debtors
      </div>

      <dl className="update-credit__data mt-4">
        <dt className="outstanding">Outstanding Balance</dt>
        <dd className="outstanding">
          {formatCurrency({
            value: customer.outstanding_balance,
            currencyCode: customer.store?.currency_code,
          })}
        </dd>

        <dt>Customer Name</dt>
        <dd>{customer.full_name}</dd>

        <dt>Total Credit</dt>
        <dd>
          {formatCurrency({
            value: customer.total_credit,
            currencyCode: customer.store?.currency_code,
          })}
        </dd>

        <dt>Total Repayment</dt>
        <dd>
          {formatCurrency({
            value: customer.total_repayment,
            currencyCode: customer.store?.currency_code,
          })}
        </dd>

        <dt>Status</dt>
        <dd>
          <span style={{ fontSize: "10px" }} className="success--squared ml-3">
            Active
          </span>
        </dd>
      </dl>

      <div className="update-credit__payment-form">
        <primaryComponents.InputField
          label={paymentType === "partial" ? "Amount" : "Full payment"}
          isRequired={true}
          value={amount}
          onChange={({ target }) => setAmount(target.value)}
          isDisabled={paymentType === "partial" ? false : true}
        ></primaryComponents.InputField>

        <div className="py-2 px-3 mt-2 border rounded">
          <primaryComponents.SelectField
            label={"Which store is the customer making the payment to?"}
            options={storeList}
            idKey="id"
            nameKey="name"
            onChange={(value) => {
              setStore(value);
            }}
            value={store || "Select store"}
            classNames="store-select"
          />

          <primaryComponents.SelectField
            options={["transfer", "card", "cash"]}
            label={"Payment Method"}
            isRequired={true}
            value={paymentMethod || "Select Payment Type"}
            onChange={(value) => setPaymentMethod(value)}
          ></primaryComponents.SelectField>
        </div>
      </div>

      <div className="py-5">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isSubmitting}
          loadingText="Updating..."
          isDisabled={isSubmitting}
          onClick={() => makeRepayment()}
        >
          Update
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default UpdateCreditWidget;
