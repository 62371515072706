import { useInfiniteQuery, useQuery } from "react-query";
import {
  getCreditLog,
  getPricingTypes,
  getProduct,
  getProductList,
  getProductUnits,
  getServiceDetails,
  getCompositeProduct,
  getProductTypes,
  getCategoriesLinkedToProduct,
  getStockTransfersList,
  getStockTransferItems,
  getStockTransferDetails,
  getStoreDetails,
  getTrackItems,
  getShippingsList,
  getShipping,
  getMenus,
  storeFrontShippingGroup,
} from "../../../services/inventory";
import { getSuppliers } from "../../../services/suppliers";
import { useDispatch } from "react-redux";
import { getCategoryList, getStoreList } from "../../../services/inventory";
import { setStoreList } from "../../../store/modules/profile";
import { getTags } from "../../../services/tags";
import { getCreditLogs } from "../../../services/credit";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { cleanObject } from "../../../helpers";

export const useGetStockTypes = () => {
  return useQuery(["getStockTypes"], () =>
    getProductTypes().then((res) => res?.data?.detail),
  );
};

export const useGetStockUnits = () => {
  return useQuery(
    ["getStockUnits"],
    () => getProductUnits().then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetCompositeProduct = ({ id, isEnabled = true }) => {
  return useQuery(
    ["getCompositeProduct", id],
    () => getCompositeProduct(id).then((res) => res?.data),
    { enabled: isEnabled, staleTime: Infinity },
  );
};

export const useGetProductDetails = (id) => {
  return useQuery(
    ["getProductDetails", id],
    () => getProduct(id).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetServiceDetails = (id) => {
  return useQuery(
    ["getServiceDetails", id],
    () => getServiceDetails(id).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetPricingTypes = () => {
  return useQuery(
    ["getPricingTypes"],
    () => getPricingTypes().then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetStoreList = (isEnabled) => {
  const dispatch = useDispatch();

  return useQuery(
    ["getStoreList"],
    () =>
      getStoreList().then((res) => {
        dispatch(setStoreList(res?.data?.data));
        return res?.data?.data;
      }),
    {
      staleTime: Infinity,
      enabled: isEnabled,
    },
  );
};

export const useGetStoreDetails = (storeId) => {
  return useQuery(
    ["getStoreDetails", storeId],
    () => getStoreDetails(storeId).then((res) => res?.data?.data),
    {
      enabled: storeId !== null,
    },
  );
};

export const useGetAllProductsList = ({
  queries = "",
  onSuccess = () => {},
  enabled = true,
}) => {
  return useQuery(
    ["getProductList", queries],
    () => getProductList(queries).then((res) => res?.data),
    {
      enabled: queries !== "" || enabled,
      staleTime: Infinity,
      onSuccess: (res) => onSuccess(res),
    },
  );
};

export const useGetTagList = (isEnabled = true) => {
  return useQuery(
    ["getTagList"],
    () => getTags().then((res) => res?.data?.data),
    {
      staleTime: Infinity,
      enabled: isEnabled,
    },
  );
};

export const useGetSuppliers = (queries = "") => {
  return useQuery(
    ["getSuppliers", queries],
    () => getSuppliers(queries).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetProductCategoryList = (isEnabled = true) => {
  return useQuery(
    ["getProductCategoryList"],
    () => getCategoryList().then((res) => res?.data?.data),
    {
      staleTime: Infinity,
      enabled: isEnabled,
    },
  );
};

export const useGetCategoriesLinkedToProduct = ({
  isEnabled = true,
  queries = "",
}) => {
  return useQuery(
    ["categoriesLinkedToProduct", queries],
    () => getCategoriesLinkedToProduct(queries).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
      enabled: isEnabled,
    },
  );
};

export const useGetCreditLog = (queries = "") => {
  return useQuery(
    ["getCreditLog", queries],
    () => getCreditLog(queries).then((res) => res?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetCreditLogDetails = (queries = "") => {
  return useQuery(
    ["getCreditLogDetails", queries],
    () => getCreditLogs(queries).then((res) => res?.data),
    {
      enabled: queries !== "",
      staleTime: Infinity,
    },
  );
};

export const useGetStockTransfersList = ({ queries = "", enabled = true }) => {
  return useQuery(
    ["getStockTransfersList", queries],
    () => getStockTransfersList(queries).then((res) => res?.data),
    {
      enabled: enabled,
    },
  );
};

export const useGetStockTransferDetails = ({ id }) => {
  return useQuery(
    ["getStockTransferDetails", id],
    () => getStockTransferDetails(id).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetStockTransferItems = ({ id }) => {
  const { ref: autoScrollRef, inView } = useInView();
  const stockTransferItemsQueries = useInfiniteQuery(
    ["getStockTransferItems", id],
    ({ pageParam }) =>
      getStockTransferItems(
        cleanObject({
          id: id,
          page: pageParam,
        }),
      ).then((res) => {
        const formattedData = res?.data?.data?.map((item) => ({
          stock_transfer_item_id: item?.id,
          name: item?.product_name,
          stock_quantity: item.product_property_from?.stock_quantity,
          quantity: item?.quantity_to_transfer,
          variant: item?.variant_name,
        }));

        return {
          data: formattedData,
          meta: res?.data?.meta,
        };
      }),
    {
      staleTime: Infinity,
      getNextPageParam: ({ meta: { next_page } }) =>
        next_page === null ? undefined : next_page,
    },
  );
  const { data, hasNextPage, fetchNextPage } = stockTransferItemsQueries;
  const stockTransferItems = new Map();
  // eslint-disable-next-line array-callback-return
  data?.pages?.map((page) => {
    stockTransferItems.set(`${page.meta.current_page}`, page?.data);
  });
  const stockTransferItemList = [].concat(...stockTransferItems.values());

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, fetchNextPage, hasNextPage]);

  return {
    ...stockTransferItemsQueries,
    autoScrollRef,
    data: stockTransferItemList,
  };
};

export const useGetTrackItems = ({
  queries = "",
  otherParams = {},
  isEnabled = true,
}) => {
  return useQuery(
    ["getTrackItems", queries, otherParams],
    () => getTrackItems(queries, otherParams).then((res) => res?.data),
    {
      staleTime: Infinity,
      enabled: isEnabled,
    },
  );
};

export const useGetShippingsList = ({ queries }) => {
  return useQuery(
    ["getShippingsList", queries],
    () => getShippingsList().then((res) => res?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetShippingDetails = (shippingId) => {
  return useQuery(
    ["getShippingDetails", shippingId],
    () => getShipping(shippingId).then((res) => res?.data?.data),
    {
      enabled: !!shippingId,
    },
  );
};

export const useGetMenus = ({ queries = "", isEnabled = true }) => {
  return useQuery(
    ["getMenus", queries],
    () => getMenus(queries).then((res) => res?.data?.data),
    {
      enabled: !!queries,
    },
  );
};

export const useGetStoreFrontShippingGroup = (menuId) => {
  return useQuery(
    ["getstoreFrontShippingGroup", menuId],
    () => storeFrontShippingGroup(menuId).then((res) => res?.data?.data),
    {
      enabled: !!menuId,
      staleTime: Infinity,
    },
  );
};
