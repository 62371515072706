import styles from "./Stores.module.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import useStores from "./useStores";
import { motion } from "framer-motion";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";

const Stores = () => {
  const {
    isCreateStoreModal,
    storeList,
    isFetchingStores,
    isLoadingStates,
    states,
    countries,
    setSelectedCountry,
    handleFetchStores,
    setIsCreateStoreModal,
  } = useStores();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Stores"
      description="View all stores"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={`${styles.storesPage} ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Stores</h4>
            <p style={{ maxWidth: "650px" }}>
              Transform your physical stores into virtual ones and manage your
              virtual stores with customizable features and settings.
            </p>
          </div>
          <div className={styles.storesPage__header}>
            <p>{storeList?.length} Stores</p>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_create_stores"
            >
              <primaryComponents.Button
                classNames={"btn btn--primary"}
                onClick={() => setIsCreateStoreModal(true)}
              >
                + Add new store
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>
          <div className={styles.storesPage__content}>
            <secondaryComponents.LoaderHelper
              classNames="mt-4"
              isLoading={isFetchingStores}
            >
              <secondaryComponents.StoresTable
                storeList={storeList}
                handleFetchStores={handleFetchStores}
                isLoadingStates={isLoadingStates}
                states={states}
                countries={countries}
                setSelectedCountry={setSelectedCountry}
              />
            </secondaryComponents.LoaderHelper>
          </div>

          {/* add a new store */}
          <secondaryComponents.Modal
            isActive={isCreateStoreModal}
            closeModal={() => setIsCreateStoreModal(false)}
            width="1200px"
          >
            <secondaryComponents.LoaderHelper
              classNames="mt-4"
              isLoading={isFetchingStores}
            >
              <secondaryComponents.StoreForm
                handleFetchStores={handleFetchStores}
                closeWidget={() => setIsCreateStoreModal(false)}
                isLoadingStates={isLoadingStates}
                states={states}
                countries={countries}
                setSelectedCountry={setSelectedCountry}
              />
            </secondaryComponents.LoaderHelper>
          </secondaryComponents.Modal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default Stores;
