import styles from "./MenuPage.module.scss";
import secondaryComponents from "../../../components/secondaryComponents";
import { motion } from "framer-motion";
import useMenuPage from "./useMenuPage";
import React, { useEffect, useRef, useState } from "react";
import Icon from "../../../assets/icons";
import AppertizerCard from "./components/AppertizerCard";
import CategorySection from "./components/CategorySection";
import MenuCart from "./components/MenuCart";
import CheckoutModal from "./components/CheckoutModal";
import OrderSummaryModal from "./components/OrderSummaryModal";
import PayForOrderModal from "./components/PayForOrderModal";
import { clearAllMenuCartItems } from "../../../store/modules/menuCart";
import { useDispatch } from "react-redux";
import {
  ChevronLeftArrow,
  ChevronRightArrow,
  Location,
} from "../../../assets/svgIcons";
import { FiPhone } from "react-icons/fi";

const ITEM_WIDTH = 264;

const MenuPage = () => {
  const {
    orderId,
    menuId,
    menuObject,
    menuCategories,
    menuSearchText,
    showCartItems,
    setShowCartItems,
  } = useMenuPage();

  const primaryColor = menuObject?.primary_colour
    ? menuObject?.primary_colour
    : "#7647ee";

  const filters = menuCategories?.map(
    (item) =>
      item.category.name.toLowerCase().charAt(0).toUpperCase() +
      item.category.name.toLowerCase().slice(1),
  );

  const dispatch = useDispatch();
  const containerRef = useRef();
  const sectionRefs = useRef([]);

  const [selectedFilter, setSelectedFilter] = useState("All");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [isActiveCart, setIsActiveCart] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showOrderSummaryModal, setShowOrderSummaryModal] = useState(false);
  const [showOrder, setShowOrder] = useState([]);
  const [showPayForOrder, setShowPayForOrder] = useState(false);
  const [selectedOrderType, setSelectedOrderType] = useState("DINE_IN");

  const handleHorizontalScroll = (scrollAmount) => {
    const newScrollPosition = scrollPosition + scrollAmount;

    setScrollPosition(newScrollPosition);

    containerRef.current.scrollLeft = newScrollPosition;
  };

  const handleScrollTop = () => {
    const currentPosition =
      window.scrollY || document.documentElement.scrollTop;
    setScrollTop(currentPosition);
  };

  const handleResize = () => {
    setInnerWidth(window.innerWidth);
  };

  const scrollToCategory = (index) => {
    if (sectionRefs.current) {
      const element = sectionRefs.current[index];
      const offsetTop = element.getBoundingClientRect().top + window.scrollY;

      if (innerWidth < 850) {
        window.scrollTo({
          top: offsetTop - 120,
          behavior: "smooth",
        });
        return;
      }

      window.scrollTo({
        top: offsetTop - 100,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (innerWidth < 850) {
      setShowCartItems(true);
    }
  }, [innerWidth]);

  useEffect(() => {
    if (scrollPosition < 0) {
      setScrollPosition(0);
    }
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollTop);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScrollTop);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (menuId && orderId) {
      dispatch(clearAllMenuCartItems());
      localStorage.removeItem("order_id");
    }
  }, [menuId, orderId]);

  return (
    <secondaryComponents.MetaSetter
      title={`Lumi Merchant | Storefront - ${menuObject?.store?.name}`}
      description="View all items on storefront"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={styles.menu}
        >
          <div className={styles.menuContainer}>
            <div className={styles.businessDetailsMobile}>
              {/* <div className={styles.logo}>Logo</div> */}
              <div className={styles.businessName}>
                <p className={styles.title}>{menuObject?.name}</p>
                <div className={styles.locationContainer}>
                  <div className={styles.location}>
                    <Location color={primaryColor} />
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${menuObject?.store?.address
                        .split(" ")
                        .join("+")}`}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.place}
                    >
                      {menuObject?.store?.address}
                    </a>
                  </div>
                </div>
                {menuObject?.store?.phone_number && (
                  <div className={styles.locationContainer}>
                    <div className={styles.location}>
                      <FiPhone color={primaryColor} />
                      <p className={styles.place}>
                        +{menuObject?.store?.phone_number}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                position: scrollTop > 90 ? "fixed" : "inherit",
                top: 90,
              }}
              className={styles.menuItemScrollContainer}
            >
              <div
                className={`${styles.menuItemScroll} ${
                  selectedFilter === "All" ? styles.active : styles.inactive
                }`}
                style={{
                  color: selectedFilter === "All" ? primaryColor : "#696677",
                  borderColor:
                    selectedFilter === "All" ? primaryColor : "#696677",
                }}
                onClick={() => {
                  setSelectedFilter("All");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                All
              </div>
              {filters?.map((item, index) => (
                <div
                  key={index}
                  className={`
                      ${styles.menuItemScroll}
                      ${
                        item === selectedFilter
                          ? styles.active
                          : styles.inactive
                      }
                    `}
                  style={{
                    color: item === selectedFilter ? primaryColor : "#696677",
                    borderColor:
                      item === selectedFilter ? primaryColor : "#696677",
                  }}
                  onClick={() => {
                    setSelectedFilter(item);
                    scrollToCategory(index);
                  }}
                >
                  {item.split(" ").map((item, index) => (
                    <span key={index}>{item}&nbsp;</span>
                  ))}
                </div>
              ))}
            </div>
            <div className={styles.sidebar}>
              <div className={styles.businessContainer}>
                {/* <div className={styles.logo}>Logo&nbsp;</div> */}
                <div className={styles.businessName}>
                  <p className={styles.title}>{menuObject?.name}</p>
                  <div className={styles.location}>
                    <div className={styles.locIcon}>
                      <Location color={primaryColor} />
                    </div>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${menuObject?.store?.address
                        .split(" ")
                        .join("+")}`}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.place}
                    >
                      {menuObject?.store?.address}
                    </a>
                  </div>
                  {menuObject?.store?.phone_number && (
                    <div className={styles.location}>
                      <div className={styles.locIcon}>
                        <FiPhone color={primaryColor} size={14} />
                      </div>
                      <p className={styles.place}>
                        +{menuObject?.store?.phone_number}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <hr />
              <div className={styles.filtersContainer}>
                <div className={styles.filter}>
                  <img src={Icon.menuFilter} alt="" />
                  <p className={styles.filterText}>Filters</p>
                </div>
                <div
                  className={styles.filterItem}
                  onClick={() => {
                    setSelectedFilter("All");
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <p
                    style={{
                      background:
                        selectedFilter === "All" ? primaryColor : "transparent",
                      color: selectedFilter === "All" ? "#ffffff" : "#15112D",
                    }}
                    className={styles.filterText}
                  >
                    All
                  </p>
                </div>
                {filters?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedFilter(item);
                      scrollToCategory(index);
                    }}
                    className={styles.filterItem}
                  >
                    <p
                      style={{
                        background:
                          item === selectedFilter
                            ? primaryColor
                            : "transparent",
                        color: item === selectedFilter ? "#ffffff" : "#15112D",
                      }}
                      className={styles.filterText}
                    >
                      {item}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.contentContainer}>
                {menuCategories?.length > 0 &&
                  menuCategories?.map((categoryItem, index) => {
                    if (index === 0) {
                      return (
                        <div
                          key={index}
                          ref={(el) => (sectionRefs.current[index] = el)}
                          className={styles.appertizerContainer}
                          style={{
                            background: menuObject?.secondary_colour
                              ? menuObject?.secondary_colour
                              : undefined,
                            padding: menuObject?.secondary_colour
                              ? 16
                              : undefined,
                            borderRadius: menuObject?.secondary_colour
                              ? 12
                              : undefined,
                          }}
                        >
                          <div className={styles.appertizerHeading}>
                            <h4>
                              {filters && filters.length > 0
                                ? `${
                                    filters[0]
                                      .toLowerCase()
                                      .charAt(0)
                                      .toUpperCase() +
                                    filters[0].toLowerCase().slice(1)
                                  }`
                                : ""}
                            </h4>
                            <div className={styles.control}>
                              <div
                                onClick={() =>
                                  handleHorizontalScroll(-ITEM_WIDTH)
                                }
                                style={{ borderColor: primaryColor }}
                              >
                                <ChevronLeftArrow color={primaryColor} />
                              </div>
                              <div
                                onClick={() =>
                                  handleHorizontalScroll(ITEM_WIDTH)
                                }
                                style={{ borderColor: primaryColor }}
                              >
                                <ChevronRightArrow color={primaryColor} />
                              </div>
                            </div>
                          </div>
                          <div
                            ref={containerRef}
                            className={styles.appertizerCardContainer}
                          >
                            {menuCategories?.length > 0 &&
                              menuCategories[0]?.menu_items
                                ?.filter((item) =>
                                  item?.name
                                    .toLowerCase()
                                    .includes(menuSearchText.toLowerCase()),
                                )
                                ?.map((item, index) => (
                                  <AppertizerCard
                                    key={item.id}
                                    productIndex={index}
                                    productItem={item}
                                    store={menuObject?.store}
                                    menuObject={menuObject}
                                  />
                                ))}
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div
                        key={index}
                        ref={(el) => (sectionRefs.current[index] = el)}
                      >
                        <CategorySection
                          index={index}
                          categoryItem={categoryItem}
                          menuObject={menuObject}
                          menuSearchText={menuSearchText}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            {showCartItems ? (
              <motion.div
                initial={{ x: 100 }}
                animate={{ x: 0 }}
                className={`${styles.cartSection} ${
                  isActiveCart ? styles.active : ""
                }`}
              >
                <MenuCart
                  isActiveCart={isActiveCart}
                  setIsActiveCart={setIsActiveCart}
                  setShowCartItems={setShowCartItems}
                  setShowCheckoutModal={setShowCheckoutModal}
                  menuObject={menuObject}
                />
              </motion.div>
            ) : null}

            <secondaryComponents.SideModal
              isActive={showCheckoutModal}
              closeModal={() => setShowCheckoutModal(false)}
              closeViaOverlay={false}
              width={600}
            >
              <CheckoutModal
                setShowCheckoutModal={setShowCheckoutModal}
                setShowOrderSummaryModal={setShowOrderSummaryModal}
                setShowOrderSummary={setShowOrder}
                setSelectedOrderType={setSelectedOrderType}
                selectedOrderType={selectedOrderType}
                menuObject={menuObject}
              />
            </secondaryComponents.SideModal>

            <secondaryComponents.SideModal
              isActive={showOrderSummaryModal}
              closeModal={() => setShowOrderSummaryModal(false)}
              closeViaOverlay={false}
            >
              <OrderSummaryModal
                setShowOrderSummaryModal={setShowOrderSummaryModal}
                setShowCartItems={setShowCartItems}
                setShowPayForOrder={setShowPayForOrder}
                showOrderSummary={showOrder}
                menuObject={menuObject}
              />
            </secondaryComponents.SideModal>

            <secondaryComponents.SideModal
              isActive={showPayForOrder}
              closeModal={() => setShowPayForOrder(false)}
              closeViaOverlay={false}
            >
              <PayForOrderModal
                setShowPayForOrder={setShowPayForOrder}
                setShowOrderSummaryModal={setShowOrderSummaryModal}
                setShowCartItems={setShowCartItems}
                selectedOrderType={selectedOrderType}
                menuObject={menuObject}
                showOrderSummary={showOrder}
              />
            </secondaryComponents.SideModal>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default MenuPage;
