import { useMutation } from "react-query";
import {
  createCompositeProduct,
  deleteVariantFromBackend,
  editCompositeProduct,
  replicateProduct,
  uploadProductImage,
  generateOrderInvoice,
  bulkUpdateProductPrice,
  approveStockTransfer,
  rejectStockTransfer,
  updateCreditLimit,
  sendCreditLimitReminder,
  updateProductTrackItem,
  deleteProductTrackItem,
  removeShipping,
} from "../../../services/inventory";
import { addCityToLocation } from "../../../services/business";
import { createShipping, updateShipping } from "../../../services/customer";

import { useContext } from "react";
import { ToastContext } from "../../context";

export const useCreateCompositeProducts = () => {
  return useMutation((data) => createCompositeProduct(data));
};

export const useEditCompositeProduct = () => {
  return useMutation((data) => editCompositeProduct(data));
};

export const useImageUploadMutation = () => {
  return useMutation((data) => uploadProductImage(data));
};

export const useReplicateProduct = () => {
  return useMutation((data) => replicateProduct(data));
};

export const useDeleteVariantFromBackend = () => {
  return useMutation((data) => deleteVariantFromBackend(data));
};

export const useGenerateInvoice = () => {
  const generateInvoice = useMutation((data) => generateOrderInvoice(data));
  const triggerToast = useContext(ToastContext);

  const handlegenerateInvoice = ({ id }) => {
    generateInvoice.mutate(
      { id },
      {
        onSuccess: (res) => {
          window.open(res?.data?.data?.url);
          triggerToast(`Invoice for order ID: ${id} generated`, "success");
        },
      },
    );
  };
  return {
    handlegenerateInvoice,
    isLoading: generateInvoice.isLoading,
  };
};

export const useBulkUpdateProductPrice = () => {
  return useMutation((data) => bulkUpdateProductPrice(data));
};

export const useApproveStockTransfer = () => {
  return useMutation((data) => approveStockTransfer(data));
};

export const useRejectStockTransfer = () => {
  return useMutation((data) => rejectStockTransfer(data));
};

export const useEditCreditLimitMutation = () => {
  return useMutation((data) => updateCreditLimit(data));
};

export const useSendCreditLimitReminderMutation = () => {
  return useMutation((data) => sendCreditLimitReminder(data));
};

export const useUpdateProductTrackItem = () => {
  return useMutation((data) => updateProductTrackItem(data));
};

export const useDeleteProductTrackItem = () => {
  return useMutation((data) => deleteProductTrackItem(data));
};

export const useCreateShippingMutation = () => {
  return useMutation((data) => createShipping(data));
};

export const useEditShippingMutation = () => {
  return useMutation((data) => updateShipping(data));
};

export const useDeleteShippingMutation = () => {
  return useMutation((data) => removeShipping(data));
};

export const useAddCityToLocation = () => {
  return useMutation((data) => addCityToLocation(data));
};
