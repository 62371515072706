import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import styles from "./CreateFoodMenu.module.scss";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import Icon from "../../../../../assets/icons";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreateFoodMenu } from "./useCreateFoodMenu";
import { Validation } from "./Validation";
import { useContext, useEffect } from "react";
import MenuDetails from "./components/MenuDetails";
import Logistics from "./components/Logistics";
import { getPlatform } from "../../../../../helpers";
import { platformHandler } from "../../../../../hooks/context";
import PaymentSetup from "./components/PaymentSetup";
// import CustomizeStorefront from "./components/CustomizeStorefront";
import CustomizationNavigation from "./components/CustomizatonNavigation";

const CreateFoodMenu = () => {
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);
  const {
    storeList,
    productsList,
    selectedMenuItems,
    isCreatingMenu,
    searchParams,
    isFetchingNextPage,
    isFetchingMenuCategories,
    isFetchingProducts,
    menuDetails,
    menuProperties,
    menuId,
    // editMenuTab,
    sortMenuTab,
    paymentMenuTab,
    logisticsMenuTab,
    menuStage,
    sortMenuCategories,
    selectedPricingType,
    setSelectedPricingType,
    setMenuStage,
    setMenuDetails,
    autoScrollRef,
    setSelectedMenuItems,
    onSubmit,
    filterProductByStore,
    handleSearchMenuItems,
    setSortMenuCategories,
    mutateSortCategories,
  } = useCreateFoodMenu();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = Validation({ menuDetails });

  useEffect(() => {
    if (menuId !== undefined && menuProperties !== undefined) {
      setMenuDetails((prev) => ({
        ...prev,
        menuName: menuProperties?.name,
        allowCheckout: menuProperties?.allow_payment,
      }));
      setValue("menuName", menuProperties?.name);
    }
  }, [menuProperties]);

  return (
    <secondaryComponents.MetaSetter
      title={"Lumi Merchant | Inventory - Create Menu"}
      description={"Create a Menu"}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        className={`${globalStyles.pagePaddings} ${styles.createFoodMenu}`}
      >
        {menuId ? (
          <div className={styles.editHeadingContainer}>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() =>
                navigate(getPlatform(platform) + "/inventory/storefront", {
                  state: {
                    menuTab: sortMenuTab
                      ? "Customize Storefront"
                      : paymentMenuTab
                        ? "Payment Method Settings"
                        : logisticsMenuTab
                          ? "Logistics Settings"
                          : "Storefront Details",
                  },
                })
              }
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
            <div className={styles.editHeading}>
              {sortMenuTab ? (
                <div>
                  <h4>{menuDetails?.menuName}</h4>
                  <p>Customize your storefront to fit your needs.</p>
                </div>
              ) : (
                <div>
                  <h4>Edit Storefront</h4>
                  <p>Update your storefront information</p>
                </div>
              )}
              {!sortMenuTab && (
                <primaryComponents.Button
                  classNames="btn btn--primary ml-auto"
                  onClick={() => {
                    if (sortMenuTab) {
                      mutateSortCategories({
                        menu_category_ids: sortMenuCategories.map(
                          (item) => `${item.id}`,
                        ),
                      });
                    } else {
                      handleSubmit(onSubmit)();
                    }
                  }}
                  isLoading={isCreatingMenu}
                  isDisabled={isCreatingMenu}
                  loadingText={"Saving..."}
                >
                  <img className="mr-2" src={Icon.saveIconAlt} alt="" />
                  <span>Save</span>
                </primaryComponents.Button>
              )}
            </div>
          </div>
        ) : (
          <div className={`${styles.createFoodMenu__header}`}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() => {
                  if (menuStage === "Logistics") {
                    return setMenuStage("Payment Setup");
                  } else if (menuStage === "Payment Setup") {
                    return setMenuStage("Sort Category");
                  } else if (menuStage === "Sort Category") {
                    return setMenuStage("Storefront Details");
                  } else {
                    navigate(-1);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon="angle-left"
                  className="mr-3"
                  style={{ fontSize: "18px" }}
                />
                <span>Back</span>
              </primaryComponents.Button>
              <h4 className="ml-2 mb-0">
                {menuId ? "Edit Storefront" : "Create Storefront"}
              </h4>
            </div>

            {!isFetchingProducts ? (
              <primaryComponents.Button
                classNames="btn btn--primary ml-auto"
                onClick={() => {
                  if (menuStage === "Payment Setup") {
                    navigate(getPlatform(platform) + "/inventory/storefront");
                  } else if (menuStage === "Sort Category") {
                    mutateSortCategories({
                      menu_category_ids: sortMenuCategories.map(
                        (item) => `${item.id}`,
                      ),
                    });
                  } else {
                    handleSubmit(onSubmit)();
                  }
                }}
                isLoading={isCreatingMenu}
                isDisabled={isCreatingMenu}
                loadingText={"Saving..."}
              >
                {menuStage === "Storefront Details" ||
                menuStage === "Sort Category" ||
                menuStage === "Payment Setup" ? (
                  <img className="mr-2" src={Icon.arrow_right} alt="" />
                ) : (
                  <img className="mr-2" src={Icon.saveIconAlt} alt="" />
                )}
                <span>Next</span>
              </primaryComponents.Button>
            ) : null}
          </div>
        )}

        <div
          style={{
            backgroundColor: "white",
            // backgroundColor:
            //   menuStage === "Sort Category" || sortMenuTab
            //     ? undefined
            //     : "white",
            // padding: 0,
          }}
          className={styles.createFoodMenu__content}
        >
          <div
            style={{
              borderBottom: 0,
              padding:
                menuStage === "Sort Category" || sortMenuTab ? 0 : undefined,
            }}
            className={`${styles.topSection} ${globalStyles.tableHeader}`}
          >
            {!menuId && (
              <div className={styles.menuCategory}>
                <div className={styles.lineContainer}>
                  <div className={styles.stageNumber}>
                    {menuStage === "Storefront Details" ? (
                      <p>1</p>
                    ) : (
                      <img src={Icon.check} alt="" />
                    )}
                  </div>
                  <div className={styles.line} />
                  <div
                    style={{
                      background:
                        menuStage === "Sort Category" ||
                        menuStage === "Payment Setup" ||
                        menuStage === "Logistics"
                          ? "#7647ee"
                          : "#F2EDFE",
                    }}
                    className={styles.stageNumber}
                  >
                    {menuStage === "Storefront Details" ||
                    menuStage === "Sort Category" ? (
                      <p
                        style={{
                          color:
                            menuStage === "Sort Category"
                              ? "#ffffff"
                              : "#7647ee",
                        }}
                      >
                        2
                      </p>
                    ) : (
                      <img src={Icon.check} alt="" />
                    )}
                  </div>
                  <div className={styles.line} />
                  <div
                    style={{
                      background:
                        menuStage === "Payment Setup" ||
                        menuStage === "Logistics"
                          ? "#7647ee"
                          : "#F2EDFE",
                    }}
                    className={styles.stageNumber}
                  >
                    {menuStage === "Storefront Details" ||
                    menuStage === "Sort Category" ||
                    menuStage === "Payment Setup" ? (
                      <p
                        style={{
                          color:
                            menuStage === "Payment Setup"
                              ? "#ffffff"
                              : "#7647ee",
                        }}
                      >
                        3
                      </p>
                    ) : (
                      <img src={Icon.check} alt="" />
                    )}
                  </div>
                  {/* <div className={styles.line} />
                  <div
                    style={{
                      background:
                        menuStage === "Logistics" ? "#7647ee" : "#F2EDFE",
                    }}
                    className={styles.stageNumber}
                  >
                    <p
                      style={{
                        color:
                          menuStage === "Logistics" ? "#ffffff" : "#7647ee",
                      }}
                    >
                      4
                    </p>
                  </div> */}
                </div>
                <div className={styles.menuCategoryText}>
                  <div>
                    <p
                      style={{
                        color:
                          menuStage === "Storefront Details"
                            ? "#7647EE"
                            : "#696677",
                      }}
                      className={styles.menuCategoryHeading}
                    >
                      Storefront Details
                    </p>
                    <p>Select product or services</p>
                  </div>
                  <div>
                    <p
                      style={{
                        color:
                          menuStage === "Sort Category" ? "#7647EE" : "#696677",
                      }}
                      className={styles.menuCategoryHeading}
                    >
                      Customization
                    </p>
                    <p>Customize your storefront to fit your needs.</p>
                  </div>
                  <div>
                    <p
                      style={{
                        color:
                          menuStage === "Payment Setup" ? "#7647EE" : "#696677",
                      }}
                      className={styles.menuCategoryHeading}
                    >
                      Set up Payments
                    </p>
                    <p>Collect payments with Paystack or Stripe</p>
                  </div>
                  {/* <div>
                    <p
                      style={{
                        color:
                          menuStage === "Logistics" ? "#7647EE" : "#696677",
                      }}
                      className={styles.menuCategoryHeading}
                    >
                      Logistics
                    </p>
                    <p>
                      Tailor your shipping process to perfectly match your
                      business needs.
                    </p>
                  </div> */}
                </div>
              </div>
            )}
            {!menuId && (
              <div className={styles.menuContainerMobile}>
                <div className={styles.menuContainerMobileHeading}>
                  <h4>
                    {menuStage === "Storefront Details"
                      ? "Storefront Details"
                      : menuStage === "Sort Category"
                        ? "Customization"
                        : "Set up Payments"}
                  </h4>
                  <p>
                    <span>
                      {menuStage === "Storefront Details"
                        ? "1"
                        : menuStage === "Sort Category"
                          ? "2"
                          : "3"}
                    </span>
                    /3
                  </p>
                </div>
                <p className={styles.menuContainerMobileDesc}>
                  {menuStage === "Storefront Details"
                    ? "Select product or services"
                    : menuStage === "Sort Category"
                      ? "Customize your storefront to fit your needs."
                      : "Collect payments with Paystack or Stripe"}
                </p>
                <div className={styles.menuContainerMobileLine}>
                  <div
                    style={{
                      width:
                        menuStage === "Storefront Details"
                          ? `${(1 / 3) * 100}%`
                          : menuStage === "Sort Category"
                            ? `${(2 / 3) * 100}%`
                            : `${(3 / 3) * 100}%`,
                    }}
                    className={styles.menuContainerMobileLineFill}
                  />
                </div>
              </div>
            )}
            {menuStage === "Storefront Details" &&
              !sortMenuTab &&
              !paymentMenuTab &&
              !logisticsMenuTab && (
                <MenuDetails
                  menuDetails={menuDetails}
                  setMenuDetails={setMenuDetails}
                  menuProperties={menuProperties}
                  storeList={storeList}
                  selectedMenuItems={selectedMenuItems}
                  searchParams={searchParams}
                  filterProductByStore={filterProductByStore}
                  handleSearchMenuItems={handleSearchMenuItems}
                  selectedPricingType={selectedPricingType}
                  setSelectedPricingType={setSelectedPricingType}
                  control={control}
                  errors={errors}
                />
              )}
            {(menuStage === "Sort Category" || sortMenuTab) && (
              <CustomizationNavigation
                menuProperties={menuProperties}
                isFetchingMenuCategories={isFetchingMenuCategories}
                sortMenuCategories={sortMenuCategories}
                setSortMenuCategories={setSortMenuCategories}
              />
            )}
            {(menuStage === "Payment Setup" || paymentMenuTab) && (
              <PaymentSetup />
            )}
            {(menuStage === "Logistics" || logisticsMenuTab) && <Logistics />}
          </div>

          {menuStage === "Storefront Details" &&
            !sortMenuTab &&
            !paymentMenuTab &&
            !logisticsMenuTab && (
              <div>
                <secondaryComponents.LoaderHelper
                  classNames={"mt-5"}
                  isLoading={isFetchingProducts}
                >
                  {menuDetails?.store !== "" ? (
                    <secondaryComponents.FoodMenuTable
                      menuItems={productsList}
                      menuProperties={menuProperties}
                      selectedPricingType={selectedPricingType}
                      selectedMenuItems={selectedMenuItems}
                      setSelectedMenuItems={setSelectedMenuItems}
                      autoScrollRef={autoScrollRef}
                    />
                  ) : (
                    <h4 className="text-center my-4">
                      Please select a store to proceed with storefront creation
                    </h4>
                  )}
                </secondaryComponents.LoaderHelper>
                <secondaryComponents.LoaderHelper
                  classNames="mt-4"
                  isLoading={isFetchingNextPage && !isFetchingProducts}
                />
              </div>
            )}
        </div>
      </motion.div>
    </secondaryComponents.MetaSetter>
  );
};

export default CreateFoodMenu;
