import styles from "./SalesTransactionsTable.module.scss";
import useSalesTransactionsTable from "./useSalesTransactionsTable";
import secondaryComponents from "..";
import Orderflows from "../StoreCheckout/Orderflows";
import { useState } from "react";

const SalesTransactionsTable = ({
  metaDetails,
  salesTransactions,
  handleFetchSalesTransactions,
  gotoPage,
  showpricing = true,
  selectedTab,
}) => {
  const [modalWidth, setModalWidth] = useState(1000);
  const { instance, selectedOrder, setSelectedOrder } =
    useSalesTransactionsTable({
      salesTransactions,
      handleRefetchOrders: handleFetchSalesTransactions,
      showpricing,
      selectedTab,
    });
  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <div className={styles.salesTransactionsTable}>
        <div className={styles.salesTransactionsTable__content}>
          <secondaryComponents.TableHelper
            getTableBodyProps={getTableBodyProps}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
          >
            <h4 className="pt-5 text-center">No Data Available</h4>
          </secondaryComponents.TableHelper>
          {/* pagination */}
          {salesTransactions?.length > 0 && (
            <div className="mt-auto">
              <secondaryComponents.Pagination
                totalPageNumber={metaDetails?.last_page}
                handlePageClick={(value) => gotoPage(value?.selected + 1)}
                currentPage={metaDetails?.current_page}
              />
            </div>
          )}
        </div>
      </div>

      <secondaryComponents.SideModal
        isActive={selectedOrder !== null}
        closeModal={() => setSelectedOrder(null)}
        width={modalWidth}
      >
        <Orderflows
          closeModal={() => setSelectedOrder(null)}
          orderId={selectedOrder}
          defaultLanding={"tnxReceipt"}
          viaSaleRecord={true}
          setModalWidth={setModalWidth}
        />
      </secondaryComponents.SideModal>
    </>
  );
};

export default SalesTransactionsTable;
