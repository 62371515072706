import styles from "./Analytics.module.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { useInventoryAnalytics } from "./useInventoryAnalytics";
import { formatCurrency } from "../../../../helpers";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { motion } from "framer-motion";

const InventoryAnalyticsPage = () => {
  const {
    startDate,
    endDate,
    analytics,
    isFetchingData,
    storeList,
    storeHasMultipleCurrency,
    currentStore,
    setCurrentStore,
    filterByStoreId,
    setDateFilter,
  } = useInventoryAnalytics();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Analytics"
      description="View insight on inventory"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`${styles["inventory-analytics"]} ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Analytics</h4>
            <p>
              Discover insights and trends from your data with interactive
              charts and graphs.
            </p>
          </div>
          <div className={styles["inventory-analytics__header"]}>
            <div style={{ width: "200px" }}>
              <primaryComponents.SelectField
                options={
                  storeHasMultipleCurrency
                    ? [...storeList]
                    : [
                        {
                          name: "All stores",
                          id: "ALL_STORES",
                        },
                        ...storeList,
                      ]
                }
                idKey="id"
                nameKey="name"
                onChange={(value) => {
                  setCurrentStore(value);
                  filterByStoreId(value);
                }}
                value={
                  storeList?.find(
                    (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                  )?.name || "All stores"
                }
                floatError={false}
                classNames="store-select"
              />
            </div>
            <primaryComponents.DateRangeInput
              startDate={startDate}
              endDate={endDate}
              onChange={setDateFilter}
            />
          </div>
          <div className={styles["inventory-analytics__cards"]}>
            <div className={styles["inventory-analytics__card"]}>
              <p>Total Revenue</p>
              <secondaryComponents.LoaderHelper
                classNames="mt-2"
                isLoading={isFetchingData}
              >
                <h5 className="mb-0">
                  {formatCurrency({
                    value: analytics?.revenue,
                    currencyCode: analytics?.store_currency_code,
                  })}
                </h5>
              </secondaryComponents.LoaderHelper>
            </div>
            <div className={styles["inventory-analytics__card"]}>
              <p>Cost of Sales</p>
              <secondaryComponents.LoaderHelper
                classNames="mt-2"
                isLoading={isFetchingData}
              >
                <h5 className="mb-0">
                  {formatCurrency({
                    value: analytics?.cost_of_goods_sold,
                    currencyCode: analytics?.store_currency_code,
                  })}
                </h5>
              </secondaryComponents.LoaderHelper>
            </div>
            <div className={styles["inventory-analytics__card"]}>
              <p>Gross Profit</p>
              <secondaryComponents.LoaderHelper
                classNames="mt-2"
                isLoading={isFetchingData}
              >
                <h5 className="mb-0">
                  {formatCurrency({
                    value: analytics?.gross_profit,
                    currencyCode: analytics?.store_currency_code,
                  })}
                </h5>
              </secondaryComponents.LoaderHelper>
            </div>
            <div className={styles["inventory-analytics__card"]}>
              <p>Inventory Worth</p>
              <secondaryComponents.LoaderHelper
                classNames="mt-2"
                isLoading={isFetchingData}
              >
                <h5 className="mb-0">
                  {formatCurrency({
                    value: analytics?.worth_of_inventory_selling_price,
                    currencyCode: analytics?.store_currency_code,
                  })}
                </h5>
                <p className="small-text-size orange--text">By Selling Price</p>
              </secondaryComponents.LoaderHelper>
            </div>
            <div className={styles["inventory-analytics__card"]}>
              <p>Total Sales Count</p>
              <secondaryComponents.LoaderHelper
                classNames="mt-2"
                isLoading={isFetchingData}
              >
                <h5 className="mb-0">{analytics?.total_sales_count}</h5>
              </secondaryComponents.LoaderHelper>
            </div>
            <div className={styles["inventory-analytics__card"]}>
              <p>Average Order Per Customer</p>
              <secondaryComponents.LoaderHelper
                classNames="mt-2"
                isLoading={isFetchingData}
              >
                <h5 className="mb-0">
                  {formatCurrency({
                    value: analytics?.average_sale,
                    currencyCode: analytics?.store_currency_code,
                  })}
                </h5>
              </secondaryComponents.LoaderHelper>
            </div>
            <div className={styles["inventory-analytics__card"]}>
              <p>Debts Owed By Customers</p>
              <secondaryComponents.LoaderHelper
                classNames="mt-2"
                isLoading={isFetchingData}
              >
                <h5 className="mb-0">
                  {formatCurrency({
                    value: analytics?.credit_amount,
                    currencyCode: analytics?.store_currency_code,
                  })}
                </h5>
              </secondaryComponents.LoaderHelper>
            </div>
            <div className={styles["inventory-analytics__card"]}>
              <p>Inventory Worth</p>
              <secondaryComponents.LoaderHelper
                classNames="mt-2"
                isLoading={isFetchingData}
              >
                <h5 className="mb-0">
                  {formatCurrency({
                    value: analytics.worth_of_inventory_cost_price,
                    currencyCode: analytics?.store_currency_code,
                  })}
                </h5>
                <p className="small-text-size orange--text">By Cost Price</p>
              </secondaryComponents.LoaderHelper>
            </div>
          </div>

          <div className={styles["inventory-analytics__performing-products"]}>
            <div
              className={
                styles["inventory-analytics__performing-products-header"]
              }
            >
              <h4
                className={
                  styles[
                    "inventory-analytics__performing-products-header__heading"
                  ]
                }
              >
                Products Performance
              </h4>
            </div>
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingData}
              classNames="mt-4"
            >
              <secondaryComponents.PerformingProductsTable
                products={analytics.top_products.map((prod) => ({
                  ...prod,
                  store_currency_code: analytics?.store_currency_code,
                }))}
              />
            </secondaryComponents.LoaderHelper>
          </div>
          <div className={styles["inventory-analytics__graphs"]}>
            <div className={styles["inventory-analytics__graphs-revenue"]}>
              <secondaryComponents.LoaderHelper
                isLoading={isFetchingData}
                classNames="mt-4"
              >
                <secondaryComponents.InventoryRevenueChart
                  data={analytics?.revenue_trend?.trend}
                  store_currency_code={analytics?.store_currency_code}
                />
              </secondaryComponents.LoaderHelper>
            </div>
            <div className={styles["inventory-analytics__graphs-profit"]}>
              <secondaryComponents.LoaderHelper
                isLoading={isFetchingData}
                classNames="mt-4"
              >
                <secondaryComponents.InventoryProfitChart
                  data={analytics?.profit_trend?.trend}
                  store_currency_code={analytics?.store_currency_code}
                />
              </secondaryComponents.LoaderHelper>
            </div>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default InventoryAnalyticsPage;
