import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ExpenseReview.module.scss";
import secondaryComponents from "..";
import useExpenseReview from "./useExpenseReview";
import primaryComponents from "../../primaryComponents";
import { useEffect } from "react";
import Validation from "./Validation";

const ExpenseReview = ({ closeWidget, expense, handleFetchExpenses }) => {
  const {
    isLoadingForm,
    expenseDetails,
    expenseCategories,
    storeList,
    isResponding,
    responseStatus,
    disbursementParameters,
    isDisbursmentForm,
    setExpenseDetails,
    handleExpenseStatus,
    setIsDisbursmentForm,
  } = useExpenseReview({
    closeWidget,
    expense,
    handleFetchExpenses,
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    register,
    setValue,
  } = Validation({ expenseDetails });

  const store_currency = storeList?.find(
    (storeItem) => `${storeItem?.id}` === `${expenseDetails?.store}`,
  )?.currency_code;

  useEffect(() => {
    window.setExpenseReviewFormValue = setValue;
  }, []);

  return (
    <>
      {!isDisbursmentForm ? (
        <div className={styles.expenseReview}>
          <div className="mb-3">
            <FontAwesomeIcon
              onClick={() => closeWidget()}
              icon="angle-left"
              style={{ fontSize: "25px", cursor: "pointer" }}
            />
          </div>
          <h4 className="mb-1">Review Expense</h4>
          <p>Record and initiate disbursements.</p>

          <secondaryComponents.LoaderHelper
            isLoading={isLoadingForm}
            classNames="mt-5"
          >
            <form className={styles.expenseReview__form}>
              <primaryComponents.SelectFieldV2
                control={control}
                name="store"
                label="Store"
                isRequired
                options={storeList}
                idKey="id"
                nameKey="name"
                onChange={(value, label) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    store: value,
                    store_label: label,
                  }));
                }}
                value={
                  expenseDetails?.store
                    ? expenseDetails?.store_label
                    : "Select stores"
                }
                classNames="store-select"
                isSearchable={false}
                errorMessage={errors.store}
              />

              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                label="Expense Name"
                placeholder="Enter expense name"
                name="expenseName"
                value={expenseDetails?.name}
                isRequired
                register={register}
                errorMessage={errors.expenseName}
                onKeyUp={() =>
                  errors.expenseName !== undefined && trigger("expenseName")
                }
                onChange={({ target: { value } }) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    name: value,
                  }));
                }}
              />

              <primaryComponents.AmountFieldV2
                control={control}
                classNames="white"
                label={`Amount (${store_currency})`}
                placeholder="Enter amount"
                value={expenseDetails?.amount}
                name="amount"
                isRequired
                isDisabled={true}
                register={register}
                errorMessage={errors.amount}
                onKeyUp={() => errors.amount !== undefined && trigger("amount")}
                onChange={({ target: { value } }) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    amount: value,
                  }));
                }}
              />

              <primaryComponents.SelectFieldV2
                label="Category"
                isRequired
                options={expenseCategories}
                nameKey={"name"}
                idKey={"id"}
                value={
                  expenseDetails?.category
                    ? expenseDetails.category_label
                    : "Choose Category"
                }
                name="category"
                control={control}
                errorMessage={errors.category}
                onChange={(value, label) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    category: value,
                    category_label: label,
                  }));
                }}
              />

              <primaryComponents.SelectFieldV2
                label="Payment Method"
                isRequired
                options={[
                  { name: "Transfer", id: "transfer" },
                  { name: "Card", id: "card" },
                  { name: "Cash", id: "cash" },
                ]}
                nameKey={"name"}
                idKey={"id"}
                name="paymentMethod"
                control={control}
                value={
                  expenseDetails?.payment_method
                    ? expenseDetails?.payment_method_label
                    : "Select payment method for this expense"
                }
                errorMessage={errors.paymentMethod}
                onChange={(value, label) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    payment_method: value,
                    payment_method_label: label,
                  }));
                }}
              />

              <primaryComponents.DateInputV2
                classNames="white"
                label="Expense Date"
                control={control}
                name="expenseDate"
                isRequired
                register={register}
                errorMessage={errors.expenseDate}
                value={expenseDetails?.expense_date}
                onKeyUp={() =>
                  errors.expenseDate !== undefined && trigger("expenseDate")
                }
                onChange={({ target: { value } }) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    expense_date: value,
                  }));
                }}
              />

              <primaryComponents.TextAreaV2
                classNames="white"
                label="Description"
                placeholder="Describe this expense "
                control={control}
                name="description"
                value={expenseDetails?.description}
                register={register}
                onChange={({ target: { value } }) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    description: value,
                  }));
                }}
              />

              <div className="mb-3">
                <label>
                  {/* <span className="text-danger">*</span> */}
                  <span>Expense Proof</span>
                </label>
                <primaryComponents.Button
                  classNames="btn btn--outline cover"
                  isDisabled={!expenseDetails?.proof_url}
                  type="button"
                  onClick={() =>
                    window.open(expenseDetails?.proof_url, "_blank")
                  }
                >
                  {expenseDetails?.proof_url ? "View Document" : "No Document"}
                </primaryComponents.Button>
              </div>

              <primaryComponents.TextArea
                classNames="white"
                label="Reason for Declination"
                placeholder="Enter reason for Declination"
                name="declinationReason"
                value={expenseDetails?.decline_reason}
                onChange={({ target: { value } }) => {
                  setExpenseDetails((prevState) => ({
                    ...prevState,
                    decline_reason: value,
                  }));
                }}
              />
            </form>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Expense"}
              permissionChildKey="can_approve_expense"
            >
              <div className={styles.expenseReview__bottom}>
                <primaryComponents.Button
                  classNames="btn btn--outline-red cover"
                  onClick={handleSubmit(() => handleExpenseStatus("decline"))}
                  isLoading={isResponding && responseStatus === "decline"}
                  isDisabled={isResponding && responseStatus === "decline"}
                  loadingText="Rejecting..."
                >
                  Reject
                </primaryComponents.Button>
                <primaryComponents.Button
                  classNames="btn btn--primary cover"
                  onClick={handleSubmit(() => handleExpenseStatus("approve"))}
                  isLoading={isResponding && responseStatus === "approve"}
                  isDisabled={isResponding && responseStatus === "approve"}
                  loadingText="Approving..."
                >
                  {expense?.make_refund ? "Disburse" : "Approve"}
                </primaryComponents.Button>
              </div>
            </secondaryComponents.NavigateWithPermission>
          </secondaryComponents.LoaderHelper>
        </div>
      ) : (
        <secondaryComponents.FundTransfer
          closeWidget={() => setIsDisbursmentForm(false)}
          externalRequest={disbursementParameters}
        />
      )}
    </>
  );
};
export default ExpenseReview;
