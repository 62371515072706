import { useContext, useMemo } from "react";
import { useTable } from "react-table";
import { formatCurrency, getPlatform, truncateString } from "../../../helpers";
import useSalesModal from "../../../hooks/useSalesModal";
import { useNavigate } from "react-router-dom";
import { platformHandler } from "../../../hooks/context";
import secondaryComponents from "..";

export const useStockHistoryTable = ({ stockHistory }) => {
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);
  const {
    isTnxRecipt,
    isSendRecipt,
    isTerminal,
    openSalesTransactionWidget,
    selectedOrder,
    orderDetails,
    setSelectedOrder,
    setOrderDetails,
    gotoTransactionDetails,
    setOpenSalesTransactionWidget,
    closeWidget,
    openSendReceipt,
    gotoReceiptTerminals,
  } = useSalesModal();

  const formatStockType = (type) => {
    if (!type) return;
    let stockType = "";

    if (type === "NEW_STOCK") stockType = "New Stock";
    if (type === "SOLD_STOCK") stockType = "Sold Stock";
    if (type === "RVSL_SOLD_STOCK") stockType = "Reversed";
    if (type === "DELETED_STOCK") stockType = "Deleted";
    if (type === "STOCK_IN_XFER") stockType = "Transfer In";
    if (type === "STOCK_OUT_XFER") stockType = "Transfer Out";
    if (type === "OVERSTOCKED") stockType = "Overstocked";
    if (type === "EXPIRED") stockType = "Expired";
    if (type === "RETURN") stockType = "Returned";
    if (type === "STOCK_IN_RECONCILIATION")
      stockType = "Stock In Reconciliation";
    if (type === "STOCK_OUT_RECONCILIATION")
      stockType = "Stock Out Reconciliation";
    if (type === "CONSUMED") stockType = "Consumed";

    return stockType;
  };

  const formatStockTypeColorCode = (type) => {
    let color = "";
    const stockType = formatStockType(type);

    if (
      stockType === "New Stock" ||
      stockType === "Transfer In" ||
      stockType === "Stock In Reconciliation"
    )
      color = "#19AE57";
    if (
      stockType === "Sold Stock" ||
      stockType === "Transfer Out" ||
      stockType === "Stock Out Reconciliation" ||
      stockType === "Consumed"
    )
      color = "#E02020";
    if (
      stockType === "Reversed" ||
      stockType === "Overstocked" ||
      stockType === "Returned"
    )
      color = "#F7AD3C";
    if (stockType === "Deleted" || stockType === "Expired") color = "#E02020";
    return color;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: (originalRow) => {
          if (originalRow?.product === null) {
            return originalRow?.product_name;
          }

          return originalRow?.product;
        },
        Cell: ({ cell: { value } }) => {
          if (value?.name !== undefined) {
            return truncateString(value?.name, 200);
          }
          if (value === null) return "N/A";

          return truncateString(value, 200);
        },
      },
      {
        Header: "Order ID",
        accessor: "order",
        Cell: ({ cell: { value } }) => {
          if (value) {
            return (
              <span
                style={{
                  color: "#7647EE",
                  height: "28px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    getPlatform(platform) +
                      `/inventory/sales-transactions?orderId=${value?.id}`,
                  )
                }
              >
                {value?.id}
              </span>
            );
          } else return "-";
        },
      },
      {
        Header: "Variant",
        accessor: (originalRow) => {
          if (originalRow?.variant === null) {
            return originalRow?.variant_name;
          }

          return originalRow?.variant;
        },
        Cell: ({ cell: { value } }) => {
          if (typeof value === "string") return value;
          if (typeof value === "object") {
            if (typeof value?.option !== "undefined") {
              return `${value?.option}-${value?.value}`;
            }
          }

          return "N/A";
        },
      },
      {
        Header: "Stock type",
        accessor: "type",
        Cell: ({ cell: { value } }) => (
          <span style={{ color: formatStockTypeColorCode(value) }}>
            {formatStockType(value)}
          </span>
        ),
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Quantity Before",
        accessor: "quantity_before",
      },
      {
        Header: "Quantity After",
        accessor: "quantity_after",
      },
      {
        Header: "Cost Price",
        accessor: "cost_price",
        Cell: ({ cell }) =>
          formatCurrency({
            value: cell.value,
            currencyCode: cell?.row?.original?.store?.currency_code,
          }),
      },
      {
        Header: "Selling Price",
        accessor: "selling_price",
        Cell: ({ cell }) =>
          formatCurrency({
            value: cell.value,
            currencyCode: cell?.row?.original?.store?.currency_code,
          }),
      },
      {
        Header: "Initiator",
        accessor: "initiator",
        Cell: ({ cell: { value } }) =>
          value !== null ? value?.first_name : "N/A",
      },
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ cell: { value } }) => <span>{value?.name || "N/A"}</span>,
      },
      {
        Header: "Tracking Number",
        accessor: "inventory_items",
        Cell: ({ cell: { value } }) => {
          const values = value?.map((item) => item?.item_number);
          return (
            <secondaryComponents.ToolTipV2 tipText={values?.join(", ")}>
              {values?.length > 0
                ? truncateString(values?.join(", "), 50)
                : "N/A"}
            </secondaryComponents.ToolTipV2>
          );
        },
      },
      {
        Header: "Supplier",
        accessor: "supplier",
        Cell: ({ cell: { value } }) => (value !== null ? value?.name : "N/A"),
      },
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => {
          return new Date(value).toLocaleString("en-us");
        },
      },
    ],
    [],
  );
  const instance = useTable({ columns, data: stockHistory });

  return {
    instance,
    isTnxRecipt,
    isSendRecipt,
    isTerminal,
    openSalesTransactionWidget,
    selectedOrder,
    orderDetails,
    setSelectedOrder,
    setOrderDetails,
    gotoTransactionDetails,
    setOpenSalesTransactionWidget,
    closeWidget,
    openSendReceipt,
    gotoReceiptTerminals,
  };
};
