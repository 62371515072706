/* eslint-disable react/jsx-key */
import styles from "./StoresTable.module.scss";
import Images from "../../../assets/images";
import useStoresTable from "./useStoresTable";
import secondaryComponents from "..";
import { useEffect } from "react";

const StoresTable = ({
  storeList,
  handleFetchStores,
  isLoadingStates,
  states,
  countries,
  setSelectedCountry,
}) => {
  const {
    instance,
    isEditModal,
    setIsEditModal,
    isFetchingStoreDetails,
    storeDetails,
  } = useStoresTable({
    storeList,
    handleFetchStores,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  useEffect(() => {
    if (storeDetails) setSelectedCountry(storeDetails?.countries?.[0]?.id);
  }, [storeDetails]);
  return (
    <>
      <div className={styles.storesTable}>
        <secondaryComponents.TableHelper
          getTableBodyProps={getTableBodyProps}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        >
          <div className="text-center">
            <img
              src={Images.storeProductEmpty}
              width="250px"
              alt="no-store-product"
            />

            <h4 className=" mt-4">You have no stores</h4>
            <p>
              Use the button above to add your stores. When you do, they would
              appear here.
            </p>
          </div>
        </secondaryComponents.TableHelper>
      </div>

      {/* edit store */}
      <secondaryComponents.Modal
        isActive={isEditModal !== null && !isFetchingStoreDetails}
        closeModal={() => setIsEditModal(null)}
        width="1200px"
      >
        <secondaryComponents.LoaderHelper
          classNames={"my-5"}
          isLoading={states === undefined && storeDetails?.countries?.[0]?.id}
        >
          <secondaryComponents.StoreForm
            closeWidget={() => setIsEditModal(null)}
            isEditMode={true}
            storeInfo={storeDetails}
            handleFetchStores={handleFetchStores}
            isLoadingStates={isLoadingStates}
            states={states}
            countries={countries}
            setSelectedCountry={setSelectedCountry}
          />
        </secondaryComponents.LoaderHelper>
      </secondaryComponents.Modal>
    </>
  );
};

export default StoresTable;
