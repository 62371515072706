import { useMemo, useState } from "react";
import { useTable } from "react-table";
import { formatCurrency } from "../../../helpers";

export const usePerformingProductsLogic = ({ products }) => {
  const [openWidget, setOpenWidget] = useState(false);

  const columns = useMemo(() => {
    return [
      {
        Header: "No.",
        accessor: (_, index) => {
          return index + 1;
        },
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Revenue",
        accessor: "revenue",
        Cell: ({ cell }) => {
          return (
            formatCurrency({
              value: cell.value,
              currencyCode: cell?.row?.original?.store_currency_code,
            }) || "N/A"
          );
        },
      },
      {
        Header: "Sales Count",
        accessor: "sales_count",
      },
    ];
  }, []);

  const instance = useTable({ columns, data: products });

  return { instance, openWidget, setOpenWidget };
};
