import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import AllCustomersLogic from "./AllCustomersLogic";
import styles from "./AllCustomers.module.scss";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../../components/primaryComponents";
import Icon from "../../../../assets/icons";

const AllCustomers = () => {
  const {
    customers,
    isFetchingCustomers,
    metaDetails,
    search,
    searchCustomers,
    gotoPage,
    filterByStoreId,
    storeList,
    storeHasMultipleCurrency,
    currentStore,
    handleFetchCustomers,
    setCurrentStore,
    isCreateCustomer,
    setIsCreateCustomer,
  } = AllCustomersLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Customers"
      description="View all customers"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={` ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Customers</h4>
            <p>
              Manage & view your customers and track their purchases &
              preferences
            </p>
          </div>
          <div className={styles.customers__header}>
            <div className={globalStyles.pageHeaders}>
              <p>{metaDetails?.total} customers</p>
            </div>
            <div className="d-flex flex-wrap">
              {/* <primaryComponents.Button classNames="btn btn--outline ml-auto m-1">
                <FontAwesomeIcon icon="download" className="mr-2" /> Export
                Customers Record
              </primaryComponents.Button> */}
              <primaryComponents.Button
                onClick={() => {
                  setIsCreateCustomer(true);
                }}
                classNames="btn btn--primary ml-auto m-1"
              >
                <img className="mr-1" src={Icon.add} alt="add-customer" /> Add
                Customers
              </primaryComponents.Button>
            </div>
          </div>

          {/* <secondaryComponents.SubAccountsList /> */}

          <div className={globalStyles.tableWrapper}>
            <div className={globalStyles.tableHeader}>
              <primaryComponents.SearchField
                classNames={globalStyles.searchField}
                placeholder="Search for customers by name or email"
                trigger={(value) => searchCustomers(value)}
                value={search.get("query") !== null ? search.get("query") : ""}
                triggerOnInput={true}
                showsearchBtn={true}
              />
              <div style={{ width: "200px" }}>
                <primaryComponents.SelectField
                  options={
                    storeHasMultipleCurrency
                      ? [...storeList]
                      : [
                          {
                            name: "All stores",
                            id: "ALL_STORES",
                          },
                          ...storeList,
                        ]
                  }
                  idKey="id"
                  nameKey="name"
                  onChange={(value) => {
                    setCurrentStore(value);
                    filterByStoreId(value);
                  }}
                  value={
                    storeList?.find(
                      (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                    )?.name || "All stores"
                  }
                  floatError={false}
                  classNames="store-select"
                />
              </div>
            </div>
            <secondaryComponents.LoaderHelper
              classNames={"mt-5"}
              isLoading={isFetchingCustomers}
            >
              <secondaryComponents.CustomersTable
                customers={customers}
                metaDetails={metaDetails}
                gotoPage={gotoPage}
                refetch={handleFetchCustomers}
              />
            </secondaryComponents.LoaderHelper>
          </div>
          <secondaryComponents.Modal
            isActive={isCreateCustomer}
            closeModal={() => {
              setIsCreateCustomer(false);
            }}
            width={600}
          >
            <secondaryComponents.CustomerForm
              closeWidget={() => setIsCreateCustomer(false)}
              customerInfo={customers}
              isEditMode={false}
              handleFetchCustomers={handleFetchCustomers}
            />
          </secondaryComponents.Modal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AllCustomers;
