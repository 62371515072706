import KYBVerification from "./KYBVerification/KYBVerification";
import Profile from "./Settings/Profile/Profile";
import Users from "./Settings/Users/Users";
import ManageRoles from "./Settings/Users/ManageRoles";
import DefaultRoles from "./Settings/Users/ManageRoles/DefaultRoles";
import CustomRoles from "./Settings/Users/ManageRoles/CustomRoles";
import RolePage from "./Settings/Users/ManageRoles/RolePage";
import Settlements from "./Settings/Settlements/Settlements";
import ApiKeys from "./Settings/APIKeys/ApiKeys";
import AccountSettings from "./Settings/AccountSettings/AccountSettings";
import Documentation from "./Documentation/Documentation";
import TransactionDetails from "./Payments/Transactions/TransactionDetails";
import CustomerDetails from "./Customers/CustomerDetails/CustomerDetails";
import AllCustomers from "./Customers/AllCustomers/AllCustomers";
import AllTransactions from "./Payments/Transactions/AllTransactions";
import Expenses from "./Expenses/Expenses";
import CreateCampaign from "./Loyalty/Campaign/CreateCampaign";
import LoyaltyNotification from "./Loyalty/LoyaltyNotification/LoyaltyNotification";
import ApprovedTransfers from "./Payments/Transfers/ApprovedTransfers/ApprovedTransfers";
import PendingTransfers from "./Payments/Transfers/PendingTransfers/PendingTransfers";
import RejectedTransfers from "./Payments/Transfers/RejectedTransfers/RejectedTransfers";
import TransferDetails from "./Payments/Transfers/TransferDetails";
import AllTransfers from "./Payments/Transfers/AllTransfers";
import AllSubAccounts from "./Payments/SubAccounts/AllSubAccounts/SubAccounts";
import CreateSubAccount from "./Payments/SubAccounts/CreateSubAccount";
import AllTerminals from "./Terminals/AllTerminals/AllTerminals";
import TerminalRequests from "./Terminals/TerminalRequests/TerminalRequests";
import NewTerminal from "./Terminals/NewTerminal/NewTerminal";
import Terminals from "./Terminals/Terminals";
import TerminalDetails from "./Terminals/TerminalDetails/TerminalDetails";
import TerminalCredentials from "./Terminals/TerminalCredentials/TerminalCredentials";
import Overview from "./Dashboard/Overview";
import CustomerAnalytics from "./Dashboard/CustomerAnalytics";
import CashflowAnalytics from "./Dashboard/CashflowAnalytics";
import SavedBeneficiaries from "./Payments/Transfers/SavedBeneficiaries/SavedBeneficiaries";
import Tags from "./Payments/Tags/Tags";
import Inventory from "./Inventory/Inventory";
import ProductCategories from "./Inventory/ProductCategories/ProductCategories";
import Stores from "./Inventory/Stores/Stores";
import AddSingleProduct from "./Inventory/SingleProducts/AddSingleProduct";
import AddMultipleProducts from "./Inventory/AddMultipleProducts/AddMultipleProducts";
import SalesTransactions from "./Inventory/SalesTransactions";
import EditStoreDetails from "./Inventory/EditStoreDetails";
import EditProduct from "./Inventory/SingleProducts/EditProduct";
import AllGiftCards from "./Loyalty/GiftCard/AllGiftCards";
import CreateGiftCard from "./Loyalty/GiftCard/CreateGiftCard";
import EditCampaign from "./Loyalty/Campaign/EditCampaign";
import EditGiftCard from "./Loyalty/GiftCard/EditGiftCard";
import LoyaltyCustomeHistory from "./Loyalty/LoyaltyCustomeHistory";
import LoyaltyCustomer from "./Loyalty/LoyaltyCustomer";
import LoyaltyCustomerDetails from "./Loyalty/LoyaltyCustomerDetails";
import StockHistory from "./Inventory/StockHistory/StockHistory";
import CreditLog from "./Inventory/CreditLogs/CreditLog";
import Subscriptions from "./Settings/Subscriptions";
import SubscriptionHistory from "./Settings/Subscriptions/SubscriptionHistory";
import SubscriptionPlans from "./Settings/Subscriptions/SubscriptionPlans";
import InventoryAnalyticsPage from "./Inventory/InventoryAnalytics/Index";
import AllStores from "./AllStores";
import AllProducts from "./Inventory/AllProducts";
import EditService from "./Inventory/SingleProducts/EditService";
import ProductStockHistory from "./Inventory/ProductStockHistory";
import CreditLogDetails from "./Inventory/CreditLogs/CreditLogDetails";
import InventoryTables from "./Inventory/Tables";
import InventoryTableDetails from "./Inventory/TableDetails";
import Suppliers from "./Inventory/Suppliers";
import EditSupplier from "./Inventory/EditSupplier";
import ExpenseDetails from "./Expenses/ExpenseDetails";
import ExpenseCategories from "./Expenses/ExpenseCategories";
import ExpenseAnalytics from "./Expenses/ExpenseAnalytics";
import AllReconciliations from "./Inventory/Reconciliation/AllReconciliations";
import ReconcileStocks from "./Inventory/Reconciliation/ReconcileStocks";
import ReconciliationReview from "./Inventory/Reconciliation/ReconciliationReview";
import AccountingOverview from "./Accounting/Accounting";
import AccountingDetails from "./Accounting/AccountingDetails";
import SupplierDetails from "./Inventory/SupplierDetails/SupplierDetails";
import BulkRestock from "./Inventory/BulkRestock";
import InvoiceDetails from "./Inventory/InvoiceDetails";
import SubscriptionSelection from "./Settings/Subscriptions/SubscriptionSelection";
import AllFoodMenu from "./Inventory/FoodMenus/AllFoodMenus";
import CreateFoodMenu from "./Inventory/FoodMenus/CreateFoodMenu";
import PaymentSetup from "./Inventory/FoodMenus/CreateFoodMenu/components/PaymentSetup";
import CreateCompositeProduct from "./Inventory/CompositeProducts/CreateCompositeProduct";
import EditCompositeProducts from "./Inventory/CompositeProducts/EditCompositeProduct";
import ProfitAndLost from "./Accounting/ProfitAndLost";
import MainDashboard from "./MainDashboard";
import AllSections from "./AllSections";
import ManageSections from "./Inventory/ManageSections";
import AllCampaigns from "./Loyalty/Campaign/AllCampaigns";
import StockTransferHistory from "./Inventory/StockTransfer/AllStockTransfer";
import CreateStockTransfer from "./Inventory/StockTransfer/CreateTransfer";
import TransferStockDetails from "./Inventory/StockTransfer/TransferStockDetails";
import EditStockTransfer from "./Inventory/StockTransfer/EditStockTransfer";
import TrackItem from "./Inventory/TrackItem";
import AllMarkets from "./MarketPlace/AllMarkets";
import MarketDetails from "./MarketPlace/MarketDetails";
import Bank from "./Accounting/Bank";
import Transaction from "./Accounting/Transaction";
import AccountingCashOnHand from "./Accounting/CashOnHand";
import AllShippings from "./Inventory/Shippings/AllShippings";
import AllRiders from "./Inventory/Riders/AllRiders";
import StorefrontSettingsCheckout from "./Inventory/FoodMenus/CreateFoodMenu/components/CustomizatonNavigation/Tabs/Checkout";
import StorefrontSettingsPayment from "./Inventory/FoodMenus/CreateFoodMenu/components/CustomizatonNavigation/Tabs/Payment";
import StorefrontSettingsBranding from "./Inventory/FoodMenus/CreateFoodMenu/components/CustomizatonNavigation/Tabs/Branding";
import StorefrontSettingsSortCategories from "./Inventory/FoodMenus/CreateFoodMenu/components/CustomizatonNavigation/Tabs/SortStorefrontCategories";

const appPages = {
  AllGiftCards,
  CreateGiftCard,
  KYBVerification,
  Profile,
  Users,
  ManageRoles,
  DefaultRoles,
  CustomRoles,
  RolePage,
  Settlements,
  ApiKeys,
  AccountSettings,
  Documentation,
  TransactionDetails,
  CustomerDetails,
  AllCustomers,
  AllTransactions,
  CreateCampaign,
  LoyaltyNotification,
  Overview,
  ApprovedTransfers,
  PendingTransfers,
  RejectedTransfers,
  TransferDetails,
  AllTransfers,
  AllSubAccounts,
  CreateSubAccount,
  AllTerminals,
  TerminalRequests,
  NewTerminal,
  Terminals,
  TerminalDetails,
  TerminalCredentials,
  CustomerAnalytics,
  CashflowAnalytics,
  SavedBeneficiaries,
  Tags,
  Inventory,
  ProductCategories,
  Stores,
  AddSingleProduct,
  AddMultipleProducts,
  SalesTransactions,
  EditStoreDetails,
  EditProduct,
  EditCampaign,
  EditGiftCard,
  LoyaltyCustomeHistory,
  LoyaltyCustomer,
  LoyaltyCustomerDetails,
  StockHistory,
  CreditLog,
  CreditLogDetails,
  Subscriptions,
  SubscriptionHistory,
  SubscriptionPlans,
  InventoryAnalyticsPage,
  AllStores,
  AllProducts,
  EditService,
  ProductStockHistory,
  InventoryTables,
  InventoryTableDetails,
  Expenses,
  ExpenseDetails,
  ExpenseCategories,
  ExpenseAnalytics,
  Suppliers,
  EditSupplier,
  AllReconciliations,
  ReconcileStocks,
  ReconciliationReview,
  AccountingOverview,
  AccountingDetails,
  SupplierDetails,
  BulkRestock,
  InvoiceDetails,
  SubscriptionSelection,
  AllFoodMenu,
  CreateFoodMenu,
  PaymentSetup,
  CreateCompositeProduct,
  EditCompositeProducts,
  ProfitAndLost,
  MainDashboard,
  AllSections,
  ManageSections,
  AllCampaigns,
  StockTransferHistory,
  CreateStockTransfer,
  TransferStockDetails,
  EditStockTransfer,
  TrackItem,
  AllMarkets,
  MarketDetails,
  Bank,
  Transaction,
  AccountingCashOnHand,
  AllShippings,
  AllRiders,
  StorefrontSettingsCheckout,
  StorefrontSettingsPayment,
  StorefrontSettingsBranding,
  StorefrontSettingsSortCategories,
};

export default appPages;
